// ===========================================
// Test info
// ===========================================
.main-test-page 
  font-size 14px
  font-weight 400
  .article-header
    margin-bottom 30px
  p + p
    margin-top 30px

.test-page
  position relative
  display flex
  flex-direction column
  min-height calc(100vh - 395px)
  padding 50px 0

  +below(1024px)
    min-height calc(100vh - 341px)
    padding 48px 0

  &.is-question
    padding-top 65px

  &.is-question:before
    content ''
    position fixed
    top 0
    left 0
    bottom 0
    background-color #F9F9F9
    width calc(((100vw - 950px) / 2) + (950px - 710px))
    z-index 0
    pointer-events none

  &.is-question &__wrapper
    flex-direction row
    align-items stretch
    justify-content space-between

  &__wrapper
    position relative
    display flex
    flex-direction column
    justify-content center
    align-items center
    flex-grow 1

.test-heading
  max-width 470px
  font-size 18px
  line-height 31px
  font-weight 400
  color $blue
  text-align center
  margin-bottom 15px

.test-description
  font-size 14px
  line-height 22px
  text-align center
  margin-top 16px
  max-width 800px

.test-list
  display flex
  margin-top 48px
  margin-bottom 80px
  width 100%
  max-width 800px

.test-list-item
  position relative
  display flex
  flex-direction column
  align-items center
  width 200px

  &:not(:last-child):after
    content ''
    position absolute
    top 18px
    right 0
    display block
    width 1px
    height 19px
    background-color #393939
    opacity 0.2

  &.is-good &__value
    color $green

  &.is-bad &__value
    color $red

  &__value
    display flex
    align-items center
    color $blue
    padding-top 16px
    padding-bottom 16px

    .is-value
      font-size 28px
      line-height 20px

    .is-unit
      line-height 20px
      margin-left 7px

  &__description
    max-width 170px
    text-align center
    font-size 14px
    line-height 19px

.test-info-buttons

  & > * + *
    margin-left 48px

// ===========================================
// Test question
// ===========================================

.test-sidebar
  display flex
  flex-direction column
  width 240px
  padding-right 40px

.test-sidebar-heading
  font-size 14px
  line-height 22px
  margin-bottom 44px

.test-sidebar-list

  &__item + &__item
    margin-top 18px

  &__label
    font-size 11px
    line-height 17px
    margin-bottom 5px

  &__value
    font-size 28px
    line-height 54px
    color $blue

    span
      color #393939
      font-size 11px
      line-height 17px

.test-main
  display flex
  flex-direction column
  width 69%

.test-main-heading
  max-width 560px
  font-size 18px
  line-height 31px
  color $blue
  margin-bottom 37px

.test-main 
  &-results
    position relative
    display inline-flex
    margin-top 40px

    &__list 
    &__items
      display flex
      flex-wrap wrap

    &__item
      display flex
      align-items center
      justify-content center
      margin 3px 5px
      width 20px
      height 20px
      border-radius 100%
      border 1px solid #D7D7D7
      font-size 10px
      color #D7D7D7

      &.is-false 
        color #ED1020
        border-color #ED1020
      &.is-true 
        color #00CB89
        border-color #00CB89
      &.is-current
        border-color $blue
        background $blue
        color #fff

  &__btn 
    position relative
    width 20px
    height 20px

    &:after
      content ""
      position absolute
      top 50%
      display block
      width 10px
      height 10px
      border solid $blue
      border-width 0 2px 2px 0

    &.is-prev
      &:after 
        transform translate(50%, -50%) rotate(135deg)
    &.is-next
      &:after 
        transform translate(50%, -50%) rotate(-45deg)
    
.test-main-list
.checkbox
  &__item
    position relative
    display flex

    & + &
      margin-top 17px

    input
      display none

  &__label
    position relative
    font-size 14px
    line-height 22px
    padding-left 33px
    font-weight 400
    cursor pointer

    &:before
      content ''
      position absolute
      left 0
      top 4px
      display block
      width 12px
      height 12px
      background-color white
      box-shadow 0 1px 3px rgba(0, 0, 0, .3)

    &:after
      content ''
      position absolute
      display none

  &__item input:checked + &__label:after
    display block

  &__item.is-radio &__label:before
    border-radius 50%

  &__item.is-radio &__label:after
    left 3px
    top 7px
    width 6px
    height 6px
    background-color $green
    border-radius 50%

  &__item.is-checkbox &__label:after
    left 2px
    top 6px
    width 9px
    height 5px
    border-left 2px solid $green
    border-bottom 2px solid $green
    transform rotate(-45deg)

.test-main-buttons
  margin-top 70px

  & > * + *
    margin-left 45px


// /*===== Popup styles ====*/
// .footer-modal {
//   position: fixed;
//   top: 0;
//   left: 0;
//   display: none;
//   opacity: 0;
//   z-index: 9999;
//   -webkit-align-items: center;
//   -ms-flex-align: center;
//   align-items: center;
//   -webkit-justify-content: center;
//   -ms-flex-pack: center;
//   justify-content: center;
//   width: 100%;
//   height: 100vh;
//   background: rgba(0,0,0,0.5);
//   transition: opacity 0.5s ease;
//   .feedback-intro {
//     padding-top: 0 !important;
//   }
//   .feedback-responce {
//     top: 50px;
//     height: calc(100% - 50px);
//   }
// }
// .popup-nevod--open {
//   display: -webkit-flex;
//   display: -ms-flexbox;
//   display: flex;
//   opacity: 1;
//   transition: opacity 0.5s ease;
// }
// .footer-modal__content {
//   position: relative;
//   max-width: 1100px;
//   max-height: 90vh;
//   width: 94%;
//   padding: 60px 0 0;
//   background-color: #fff;
//   .form-holder {
//     padding: 50px 0;
//     max-width: 100%;
//     overflow: hidden;
//     textarea {
//       min-height:120px;
//     }
//   }
// }
// .scrollable {
//   position: relative;
//   max-height: calc(90vh - 60px);
//   width: 100%;
//   padding: 0 15px;
//   overflow-y: auto;
// }
// .close-footer-modal {
//   position: absolute;
//   top: 22px;
//   right: 22px;
//   width: 25px;
//   height: 25px;
//   border: none;
//   outline: none;
//   background: none;
//   cursor: pointer;
//   z-index: 999;
// }
// .close-footer-modal:before, 
// .close-footer-modal:after {
//     content: ' ';
//     position: absolute;
//     top: 0;
//     left: 50%;
//     height: 100%;
//     width: 2px;
//     background-color: #d8d8d8;
// }
// .close-footer-modal:before {
//     -webkit-transform: rotate(45deg);
//     -ms-transform: rotate(45deg);
//     transform: rotate(45deg);
// }
// .close-footer-modal:after {
//     -webkit-transform: rotate(-45deg);
//     -ms-transform: rotate(-45deg);
//     transform: rotate(-45deg);
// }
// .close-footer-modal:hover:after, .close-footer-modal:hover:before {
//     background-color: #444;
// }
// .overflowed {
//   height: 100vh;
//   overflow: hidden;
// }
// @media all and (max-width: 480px) {
//   .footer-modal__content {
//     height: 100vh;
//     max-height: 100vh;
//     width: 100%;
//   }
//   .scrollable  {
//     max-height: calc(100vh - 60px);
//   }
// }
/*!===== Article styles, Contests and News Page styles ====*/
.contests-page
  h2
    margin-bottom 40px
  
  .tag
    pointer-events none

.top-news
  position relative
  display flex
  align-items center
  justify-content space-between
  margin-bottom 90px
  &-pic
    max-width 510px
    width 55%
    min-width 350px;
    height 100%
  &__img
    width 100%
    height 100%
    object-fit cover
    font-family 'object-fit: cover'
  &-content
    width 45%
    max-width 400px
    padding 30px 15px
  &__heading
    margin-bottom 30px

.btn-wrap + .article
  margin-top 60px

.article + .btn-wrap
    margin-top 60px

.article-page
  font-weight 400
  font-size 14px

  .wrapper 
    > * + * 
      margin-top 60px
      
      +below(1024px)
        margin-top 45px

.article-content > img 
    max-width 100% !important 
    height auto !important

.article
  &--contest 
    .article-heading 
      margin-top 0
    .tag
      display none
  .tag
    margin-bottom 0
  &-header
    margin-bottom 50px
    * + *
      margin-top 30px
  &__date
    font-size 11px
  &__title
    font-size 18px
    line-height 31px
  &__img
    max-width 100%
  &-content
    * + *
      margin-top 50px
  &-list
    &__item
      position relative
      padding-left 40px
      &:before
        content ''
        position absolute
        left 16px
        top 7px
        width 8px
        height 1px
        background-color #656565
      & + &
        margin-top 30px
  &-slider
    position relative
    &__item
      margin-top 0
    &__img
      width 100%
      object-fit cover
      font-family 'object-fit: cover'

    .slick-arrow
      position absolute
      top 50%
      display block
      margin-top 0
      width 30px
      height 30px
      cursor pointer
      background transparent
      font-size 0
      line-height 0

      &:after
        content ''
        position absolute
        top 50%
        left 50%
        width 8px
        height 8px
        transform translate(-50%, -50%) rotate(45deg)
        border solid $blue
        transition border-color .33s ease

      &:hover
        border-color $blueHover
        transition border-color .33s ease

    .slick-prev
      right -50px
      &:after
        border-width 0 0 2px 2px

    .slick-next
      right -83px
      &:after
        border-width 2px 2px 0 0

    &__counter
      position absolute
      right -81px
      top 50%
      transform translate(0, -100%)
      margin-top 0

      li
        display none
        margin-top 0
        font-size 28px
        font-weight 100
        line-height 28px
        color $blue

        &.slick-active
          display block
          &:first-letter
            color $blue

    &__total
      color #a7a7a7

.input-file
  position relative
  padding-left 30px
  color $blue
  font-size 13px
  font-weight 700
  cursor pointer

  // &:before
  //   content ''
  //   position absolute
  //   left 0
  //   top 0
  //   width 12px
  //   height 12px
    
  //   background-size cover
  //   font-family 'object-fit: cover'

  &__preview 
    position relative
    flex-shrink 0
    margin-right 15px
    width 40px
    height 40px
    border 1px solid #0071b9
    background-image embedurl('../img/input-file.svg')
    background-repeat no-repeat
    background-position center

    &.is-downloaded 
      background-size 30%
      background-position-x: 25px;
      background-position-y: 25px;
      background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/PjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+PHN2ZyB2ZXJzaW9uPSIxLjEiIGlkPSJDYXBhXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IiB3aWR0aD0iNDg5Ljc5M3B4IiBoZWlnaHQ9IjQ4OS43OTNweCIgdmlld0JveD0iMCAwIDQ4OS43OTMgNDg5Ljc5MyIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgNDg5Ljc5MyA0ODkuNzkzOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+PGc+PGc+PHBvbHlnb24gcG9pbnRzPSI0ODkuNzkzLDM1OS44MzIgNDQ0LjcxOSwzNTkuODMyIDQ0NC43MTksNDMxLjk0OCA0NS4wNzMsNDMxLjk0OCA0NS4wNzMsMzYxLjMzNCAwLDM2MS4zMzQgMCw0NzcuMDIxIDQ4OS43OTMsNDc3LjAyMSIgZmlsbD0nIzAwNzFiOScvPjxwb2x5Z29uIHBvaW50cz0iMTMxLjY3LDE3NC40NjIgNjkuMDY3LDE3NC40NjIgMjQ5LjYyOCwzOTguODk2IDQzMC4xODgsMTc0LjQ2MiAzNjcuNTg2LDE3NC40NjIgMzY3LjU4NiwxMi43NzEgMTMxLjY3LDEyLjc3MSAiIGZpbGw9JyMwMDcxYjknLz48L2c+PC9nPjxnPjwvZz48Zz48L2c+PGc+PC9nPjxnPjwvZz48Zz48L2c+PGc+PC9nPjxnPjwvZz48Zz48L2c+PGc+PC9nPjxnPjwvZz48Zz48L2c+PGc+PC9nPjxnPjwvZz48Zz48L2c+PGc+PC9nPjwvc3ZnPg==")

    img 
      position relative
      z-index 2
      width 100%
      height 100% !important
      object-fit cover
      font-family 'object-fit: cover'

  &__name 
      position absolute
      top 50%
      left 50%
      transform translate(-50%, -50%)
      display block
    
  input
    display none

.comments-form__row
  .input-file
    display flex
    align-items center
    padding-left 0

.input-file-hint
  font-size 12px

  &.is-error
    color red

.article + .article-page-section
  margin-top 50px
.article-page-section
  & + &
    margin-top 70px
  >* + *
    margin-top 10px
  .article-heading + *
    margin-top 40px

.article-user
  padding 22px 40px 60px 80px
  border-radius 12px
  box-shadow: 1px 2px 6px 0 rgba(0, 0, 0, 0.16);
  &__header
  &__info
    display flex
    align-items center
    justify-content space-between
  &__pic
    margin 0 20px 0 -61px
    width 41px
    height 41px
  &__date
    font-size 11px
  &__name
    font-size 13px
    font-weight 700
  &__content
    margin-top 20px
    * + *
      margin-top 30px
    p
      width 100%
  &__img
    max-width 50%

.comments
  &-form
    display flex
    padding 35px 50px 40px 30px
    border-radius 12px
    box-shadow: 1px 2px 6px 0 rgba(0, 0, 0, 0.16);
    &--unboxed
      padding 0
      border-radius 0
      box-shadow none
    &__pic
      flex-shrink 0
      margin-right 30px
      width 60px
      height 60px
    &-item
      width 100%

    &__field
      margin-bottom 30px
      min-height 78px
      width 100%
      padding 15px
      border 1px solid #d9d9d9
      border-radius 6px
      font-size 14px
      font-weight 400
      color #393939

    &__row
      margin-bottom 15px

      &.is-space-between
        display flex
        align-items center
        justify-content space-between

    &__btns
      display flex
      justify-content flex-end

      button:not(:last-child)
        margin-right 30px
  
textarea.error 
  margin-bottom 0
  border-color #f00

  & + label.error 
    display block
    margin-bottom 8px

.question-form
  .comments-form__field
    margin-bottom 20px

  .comments-form__btns
    margin-top -32px

/*!===== end Article styles, Contests and News Page styles ====*/

/*!===== 768 styles ====*/
+above(1023px)
    section .wrapper > *
        opacity 0
        transform translateY(50px)
        
        &.is-visible 
            opacity 1
            transform translate(0)
            transition opacity 0.7s cubic-bezier(0.25, 0.1, 0, 0.85), transform 0.7s cubic-bezier(0.25, 0.1, 0, 0.85)
    .achievements-list-extended
        display flex
        flex-wrap wrap
        justify-content flex-start
        padding-left 44px
        &__item:nth-child(odd)
            width 280px
            margin-right: 35px

+below(1024px)
    body
        padding-top 66px
    .wrapper
        padding 0 69px
        &--secondary
            padding-right 30px
        &--inner
            max-width 850px
    .studies
    .questions
    .filter-list
        align-self center
        max-width 640px
    .filter-list__item
        width 31%
    .header
        height: 70px

        .main-menu
            border-bottom none
            &__link
                height: 70px
        &--scroll
            .profile
                transform none

        &-wrapper
            padding 0 30px
        &--secondary
            background-color $blues
        
    .profile
    .logo-wrap
        transform translate(0, 0)
        transition transform .33s ease

    .logo
        &-link
            width 100px
        &-static
            display none
        &-scroll
            display block
            visibility visible
            transform scale(1)
        

    .profile
        min-width auto
        &__btn
            display none
        &-item
            margin-top: 16px
            position static
            width auto
            box-shadow none
            background transparent
        &-user
            padding:0;
            height: auto
            &__pic
                margin 0
            &__link
                padding 0
                &--notification
                    &:before
                        content ''
                        position absolute
                        top 0
                        right -6px
                        width 13px
                        height 13px
                        border 4px solid #ff0000
                        border-radius 50%
                        background-color #fff
                    
                span 
                    display none
                &:after
                    display none

    .achievements-list-extended
        padding-left 82px
        margin-bottom -10px
        &__item
            margin-bottom 10px
            &:hover
                .bubble
                    opacity 0
                    visibility hidden
        &__image
            margin-right 23px
            width 55px
            height 55px
        &__title
            .icon-info
                .bubble
                    left 0
                    bottom 100%
                    max-width 100%
                    &:after
                    &:before
                        transform none
                        right auto
                        left 0
                    &:after
                        margin 0
                    &:before
                        margin 0 0 0 -1px
        .icon-info:hover
            .bubble
                opacity 1
                visibility visible
    .study-preview-list
        &__item
            width 288px

    .study-preview
        line-height 18px

    .page
        padding 48px 0 80px
    .article-page 
        padding 70px 0 90px
    .intro-section
        margin-top -66px
        padding 66px 0
    .main-section
        padding 75px 0 60px
        .studies-item
        .questions-item
            &:nth-child(n+5)
                display none

    .md-visible
        display flex

    .section-header 
        margin-bottom 55px
    
    .top-news-content
        margin-right -40px
        padding-left 10px

    .studies-page
    .contests-page
        .article-heading
            text-align center
    
    .article-slider
        padding-bottom 50px
        &__counter
        .slick-arrow    
            bottom 0
            top auto
            right auto
            transform translate(0,0)
        &__counter
            left 0
        .slick-prev
            left 100px
        .slick-next
            left 130px

    .notification-atestation
        flex-direction column
        &-text
            margin-right 0
        &-btn
            align-self flex-start
            margin-top 15px
    .authorization
        margin-top -66px
    .personal-left-block
        width auto
        padding-right 50px
    .achievements-list__item 
        margin 20px
        width 55px
        height 55px
    
    .test-page.is-question:before
        max-width 270px
        width 30%
    .test-main
        width 75%
    .page404
        height calc(100vh - 341px)

+below(768px)
    body
        padding-top 53px
    .wrapper
        padding 0 15px
        &--secondary
        &--inner
            padding-right 15px
    .header-wrapper
        align-items center
        padding 0 15px
        height 100%
    .header--secondary
    .header--scroll
        .mobile-nav:not(.mobile-nav--open)
            span 
                background-color $white
                transition all .33s ease
    .profile    
        margin-left auto
        margin-right 30px

    .achievements-list-extended
        padding-left 30px

    .study-preview-list
        &__item
            width 100%

    .mobile-nav
        display block
        &--open 
            z-index 1000
            
            span
                background-color #fff
    .main-nav
        position fixed
        left -110%
        top 0
        display flex
        align-items center
        justify-content center
        width 100vw
        height 100vh
        background-color $blue
        transition transform .33s ease

        &--open
            display flex
            transform translateX(110%)
            transition transform .33s ease
            z-index 999
            .main-menu
                opacity 1
                transition opacity .33s ease-in-out
                transition-delay .33s

    .main-menu 
        flex-direction column
        margin 0
        width 100%
        border-bottom none
        opacity 0
        &__item
            & + &
                margin-left 0
        &__link
            font-size 18px
            line-height 27px
            text-transform uppercase
        .main-menu__link
            color #fff
    .studies-item
    .questions-item
        width 48%
    .link--back
        margin-left 0
    .page   
        padding 40px 0 60px
    .contests-page
    .news-page  
        padding-top 0
    .top-news
        margin 0 -15px 40px
        min-height 400px
        color #fff
        &-content
            width 100%
            padding 30px 15px 50px
        .tag
            border-color #fff
            &:before
                background-color #fff
        .news-heading
            color #fff
        &-pic
            position absolute
            top 0
            left 0
            max-width 100%
            width 100%
            height 100%
            z-index -1

    .test-main
        width 90%
        margin-left 30px

    .old-browser__title span 
        display block
        margin 0 auto

+below(568px)
    .header 
        height 53px
    .main-heading
        font-size 36px
        line-height 36px
    .section-heading
        font-size 30px
    .article-heading
        font-size 24px
        line-height 30px
    .intro-section
        font-size 13px
    .section-header 
        margin-bottom 40px
    .main-section
        padding 60px 0 

        &.questions-section
            position relative
            padding-bottom 100px

            .questions-section__link
                position absolute
                bottom 40px
                left 50%
                transform translateX(-50%)
                min-width 170px

    .md-visible
        margin-top 50px

    .result-popup-container
        padding 30px 15px

    .top-news
        min-height 320px

    .studies
    .questions
        max-width 310px
        &-item
            width 100%
    .main-section
        .studies-item
        .questions-item
            &:nth-child(n+4)
                display none

    .btn-wrap + .article
        margin-top 45px
    
    .article + .btn-wrap 
        margin-top 45px

    .article-page .btn-wrap:last-of-type
        margin-top 40px
    
    .article
        &-header
            margin-bottom 30px
            >*+*
                margin-top 20px
        .article-heading
            font-size 18px
            line-height 27px
        &__title
            font-size 15px
            line-height 22px
        &-content
            >* + *
                margin-top 30px
        &-user
            padding 0 0 25px
            border none
            box-shadow none
            border-radius 0
            border-bottom 1px solid #979797
            &__content
                * + *
                    margin-top 19px

            &__pic
                margin-left 0
    .article-page-section >* + *
        margin-top 35px
    .comments-form
        flex-direction column
        &__pic
            margin-bottom 15px
            width 41px
            height 41px
        &__field
            margin-bottom 10px
        &__btns
            flex-direction row-reverse
            button
                margin-left 0
                margin-right 30px

    .comments-form:not(.comments-form--unboxed)
        padding 0 0 25px
        border none
        box-shadow none
        border-radius 0
        border-bottom 1px solid #979797
        
    .questions
        &-header
            flex-direction column
            >* + *
                margin-top 25px
            &-text 
                margin-right 0
                max-width 100%
                * + *
                    margin-top 19px
        &-title
            font-size 14px
        &-box
            margin-top 55px
            &-header
                display flex
            &__btn
                font-size 14px
                text-align left
                & + & 
                    padding-left 30px
                    border-left 1px solid #979797
        &-list
            &__item
                margin  0 -15px
                .is-top,
                .is-bottom
                    right 20px
                    bottom 5px
                    top auto
            &__question
            &__answer
                padding 30px 15px
            &__content
                padding-left 0

    .notification
    .personal
        display none
        &.is-open
            display block

    .personal-page
        &-tabs
            display block
        &__tab
            margin-right 28px
            color $blue
            font-size 14px
            font-weight: 500;
            line-height: 1.72;
            opacity .5
            cursor: pointer;

            &.is-active
                opacity 1
            &+&
                padding-left 28px
                border-left 1px solid #979797

    .notification-block
        margin-top 0
        padding 30px 45px 30px 30px
        border-bottom 1px solid #979797
        color #393939
        background-color transparent
        &:before
            width 4px
            left 0
            background-color transparent
            background center no-repeat embedurl('../img/attention-ico-2.svg')
        &__close
            right 0
            &:before
            &:after
                background-color $blue

    .notification-atestation
        margin-top 30px
        padding 0
        border none
    .personal
        &-information, 
        &-block,
        &-info
            flex-direction column
        &-info
            &__row
                margin-bottom 30px
                width 100%
                padding 0
            &__btns
                flex-direction column-reverse
                align-items flex-start
                padding 0 0 25px
                border-top none
                border-bottom solid 1px #e7e7e7
                .btn
                    margin-bottom 35px
        &-form-wrap
            align-items flex-start
            margin-bottom 30px
        &-img-form
            display flex
        &-photo
            margin-right 30px
            margin-bottom 0
            width 80px
            height 80px
        &-left-block
            padding-right 0
        &-rating
            padding-bottom 40px
            border-bottom solid 1px #e7e7e7
            >* + *
                margin-top 20px
        &-achievements
            padding-top 30px
            border-left none

    .achievements-list-extended
        padding-left 0
        .personal-page__subheading + &
            margin-top 20px

    .achievements-list
        justify-content center
        margin-top 0
        &__item
            width 75px
            height 75px

    .personal-information + .personal-block
        margin-top 30px

    .test
        &-heading
            text-align left
            line-height 27px
        &-list
            flex-direction column
            margin 20px 0 50px
            &-item
                flex-direction row
                width 100%
                &:not(:last-child):after
                    display none
                &__description
                    max-width 100%
                    text-align left
                    font-size 15px
                &__value
                    margin-right 15px
                    min-width 70px
        &-page
            &.is-question 
                padding-top 0
                &:before
                    top 53px
                    max-width 100%
                    width 100%
                    height 82px
                    background-color #e8f4fc
                .test-page__wrapper
                    flex-direction column
                    justify-content flex-start
        &-sidebar
            width 100%
            padding 5px 0
            &-heading
                display none
            &-list
                display flex
                &-item
                    &+&
                        margin-left 30px
                &__value
                    font-size 18px
                    font-weight 400
        &-main
            margin-left 0
            width 100%
            &-heading
                margin 20px 0
                font-size 14px
                line-height 22px
            &-buttons
                display flex
                margin-top 40px
    .rating-page-header 
        margin-bottom 40px
        * + *
            margin-top 12px
    .rating-list
        margin  0 -15px 50px
        &__item
            padding-left 46px
            padding-right 28px
        &__count
            width 46px
        &__pic
            margin-right 15px
    .filter-list
        display none
    .studies-select
        display block
    .footer-info
        flex-direction column
        justify-content center
        align-items center
        &__wrap
            &.is-left
            &.is-right
                text-align center
            & + &
                margin-top 21px
    .page404
        height auto
        min-height calc(100vh - 436px)
    .page404-container 
        font-size 14px
        line-height 22px
    .page404-heading
        margin-bottom 22px
        font-size 70px
        line-height 84px


+below(640px)
    .question-form

        .comments-form__btns
            margin-top 20px
            flex-direction: row-reverse

            button
                margin-left 0
                margin-right: 30px

/*!===== end 768 styles ====*/
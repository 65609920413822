/*!===== studies-list styles ====*/

.studies 
  display flex
  flex-wrap wrap
  margin 0 -5px

  &-item
    position relative
    margin 5px
    max-width 310px
    min-width 260px
    padding 27px 0 90px
    min-height 409px
    object-fit contain
    border-radius 12px
    background-color #ffffff
    box-shadow 1px 2px 6px 0 rgba(0, 0, 0, 0.16)
    &:last-child
      margin-right auto

    &__tag
      margin-left 25px
      margin-right 25px
    
    &__pic
      margin-bottom 25px
      width 100%
      height 164px
      
    &__img
      width 100%
      height 100%
      object-fit cover
      font-family 'object-fit: cover'

    &__content
      padding 0 27px
      color #0071b9

    &__btn
      position absolute
      bottom 33px
      left 27px

/*!===== end studies-list styles ====*/
@font-face {
    font-family: 'Centrale';
    src: url('../fonts/cenrale-regular.woff2') format('woff2'),
         url('../fonts/cenrale-regular.woff') format('woff'),
         url('../fonts/cenrale-regular.svg#centrale_sansbook') format('svg');
    font-weight: 400;
    font-style: normal;

}

@font-face {
    font-family: 'Centrale';
    src: url('../fonts/centrale-bold.woff2') format('woff2'),
         url('../fonts/centrale-bold.woff') format('woff'),
         url('../fonts/centrale-bold.svg#centrale_sansbold') format('svg');
    font-weight: 700;
    font-style: normal;

}

@font-face {
    font-family: 'Centrale';
    src: url('../fonts/centrale-light.woff2') format('woff2'),
         url('../fonts/centrale-light.woff') format('woff'),
         url('../fonts/centrale-light.svg#centrale_sanslight') format('svg');
    font-weight: 300;
    font-style: normal;

}

@font-face {
    font-family: 'Centrale';
    src: url('../fonts/centrale-medium.woff2') format('woff2'),
         url('../fonts/centrale-medium.woff') format('woff'),
         url('../fonts/centrale-medium.svg#centrale_sansmedium') format('svg');
    font-weight: 500;
    font-style: normal;

}

@font-face {
    font-family: 'Centrale';
    src: url('../fonts/centrale-thin.woff2') format('woff2'),
         url('../fonts/centrale-thin.woff') format('woff'),
         url('../fonts/centrale-thin.svg#centrale_sansthin') format('svg');
    font-weight: 100;
    font-style: normal;

}

@font-face {
    font-family: 'Centrale';
    src: url('../fonts/centrale-xbold.woff2') format('woff2'),
         url('../fonts/centrale-xbold.woff') format('woff'),
         url('../fonts/centrale-xbold.svg#centrale_sansxbold') format('svg');
    font-weight: 900;
    font-style: normal;

}

body
  padding-top 120px
  font 16px/22px 'Centrale', sans-serif
  font-weight 500
  background-color #fff
  color #393939

.body-noscroll
  overflow hidden

a
  text-decoration none

ul
  list-style-type none

button
  outline none
  border none
  background transparent
  cursor pointer
  
input
textarea
  border none
  border-radius 0
  outline none

input:not([type=checkbox]):not([type=radio])
textarea
  appearance none

.wrapper
  display flex
  flex-direction column
  margin 0 auto
  // padding 0 37px
  width 100%
  max-width 950px
  &--secondary
    max-width 790px
  &--inner
    max-width 711px

.main-heading
  font-size 55px
  line-height 54px
  letter-spacing 1.3px
  font-weight 900

.section-heading
  color $blue
  text-align center
  font-size 50px
  line-height 54px
  font-weight 300

.article-heading
  color $blue
  font-size 35px
  line-height 42px
  font-weight 400

.news-heading
  color $blue
  font-size 18px
  line-height 31px
  font-weight 500

.small-text
  font-size 11px

.btn-wrap
  display flex
  &--centered
    align-items center
    justify-content center
    text-align center

.link
.btn
  position relative
  display inline-flex
  align-items center
  justify-content center
  font-size 13px
  line-height 15px
  font-weight 700
  transition all .33s ease
  cursor pointer

  &:after
    content ''
    position absolute
    top 50%
    transform translateY(-50%)
    display block
    width 0
    height 0
    border-top 3px solid transparent
    border-bottom 3px solid transparent
    transition all .33s ease

  &:hover
    transition all .33s ease

.btn
  height 41px
  padding 5px 37px 5px 20px
  border-radius 6px

  &:after
    right 20px

  &--bordered
    color $blue
    background-color #fff
    border 1px solid transparent
    box-shadow 0 1px 3px 0 rgba(0, 0, 0, 0.29)
    &:after
      border-left 3px solid $blue

    &:hover
      border-color $blueHover
      color $blueHover
      &:after
        border-left 3px solid $blueHover


  &--filled
    color #fff
    background-color $blue
    transition-property background-color !important
    &:after
      border-left 3px solid #fff

    &:hover
      background-color $blueHover

.link
  padding-right 12px
  color $blue

  &:after
    right 0
    border-left 3px solid $blue

  &:hover
    color $blueHover
    &:after
      border-left-color $blueHover

  &--back
    margin-left -12px
    padding-left 12px
    &:after
      left 0
      right auto
      border-left none
      border-right 3px solid $blue
    &:hover:after
      border-right-color $blueHover

  &--down
    padding-right 20px
    &:after
      border-left 3px solid transparent
      border-right 3px solid transparent
      border-top 3px solid $blue

.circle-img
  border-radius 50%
  box-shadow 0 1px 3px 0 rgba(0, 0, 0, 0.29)
  border solid 2px #ffffff

  img
    width 100%
    height 100%
    object-fit cover
    font-family 'object-fit: cover'
    border-radius 50%

.tag
  position relative
  display inline-flex
  align-items center
  margin-bottom 30px
  padding 5px 11px 5px 23px
  min-height 24px
  border-radius 4px
  border 1px solid $blue
  font-size 11px
  line-height 11px
  transition all .33s ease

  &:before
    content ''
    position absolute
    top 50%
    left 10px
    transform translateY(-50%)
    width 4px
    height 4px
    border-radius 50%
    background-color #0071b9
    transition all .33s ease


  &--secondary
    border-color #fff

    &:before
      background-color #fff

  &:hover:not(.tag--secondary)
    color $blueHover
    border-color $blueHover
    transition all .33s ease
    &:before
      background-color $blueHover
      transition all .33s ease

.is-invisible
  opacity 0
  pointer-events none 
  visibility hidden

.is-right
.is-left
.is-bottom
.is-top
  &:after
    content ''
    position absolute
    top 50%
    width 8px
    height 8px
    transform translateY(-50%) rotate(45deg)
    border solid $blue

.is-right:after
  border-width 2px 2px 0 0
.is-left:after
  border-width 0 0 2px 2px
.is-bottom:after
  border-width 0 2px 2px 0
.is-top:after
  border-width 2px 0 0 2px

.close-ico
  cursor pointer
  &:before
  &:after
    content ' '
    position absolute
    top 50%
    left 50%
    height 12px
    width 2px
    transition background .33s ease
  &:hover
    &:before
    &:after
      background-color $blueHover
      transition background .33s ease
  &:before
    transform translate(-50%, -50%) rotate(45deg)
  &:after
    transform translate(-50%, -50%) rotate(-45deg)

.intro
  &-section
    position relative
    display flex
    align-items center
    margin-top -120px
    height 100vh
    width 100%
    padding 120px 0
    color #fff

  &-heading
    margin-bottom 18px

  &-bg
    position absolute
    top 0
    left 0
    width 100%
    height 100%
    z-index -1

    &__img
      width 100%
      height 100%
      object-fit cover
      font-family 'object-fit: cover'

  &-content
    max-width 510px

    p + p
      margin-top 18px
    p + .btn
      margin-top 45px

.main-section
  padding 110px 0

.section-header
  margin-bottom 80px
  text-align center
  * + *
    margin-top 25px

.md-visible
    display none
    margin-top 60px

.questions
  display flex
  flex-wrap wrap
  margin 0 -5px

  &-section
    background-color $blue

    &__link
      color #fff
      &:after
        border-left-color #fff

    .section-heading
      color #fff

  &-item
    position relative
    margin 5px
    max-width 310px
    width 100%
    padding 22px 21px 90px
    min-height 213px
    object-fit contain
    border-radius 12px
    background-color #ffffff
    box-shadow 1px 2px 6px 0 rgba(0, 0, 0, 0.16)
    &:last-child
      margin-right auto

    &__info
      display flex
      align-items center
      margin-bottom 30px

    &__pic
      position relative
      margin-right 18px
      width 41px
      height 41px

    &__name
      font-size 13px
      font-weight 700

    &__date
      margin-bottom 5px
      font-size 11px
      line-height 17px

    &__content
      margin-bottom 25px

    &__text
      color $blue
    
    .btn 
      position absolute
      left 21px
      bottom 33px

.is-admin:after
  content ''
  position absolute
  top 0
  right -10px
  width 18px
  height 18px
  background-image: embedurl('../img/admin-ico.svg');

/*!===== Form styles ====*/

.form
  font-size 14px
  font-weight 400
  &-heading
    margin-bottom 35px
    text-align center
  &-personal__photo
    margin-bottom 50px
  &-row
    display flex
    flex-direction column
    width 100%
    & + &
      margin-top 13px
    & + &--submit
      margin-top 35px
    &--centered
      text-align center
    &--message
      min-height 22px
    &--submit
      flex-direction row
      justify-content center
  &-required__field
    font-size 11px
    font-weight 400

  &-input
    margin-top 6px
    width 100%
    height 45px
    padding 10px 18px
    border-radius 6px
    border solid 1px #d4d4d4
    transition border-color .33s ease
    &:hover
      border-color #79c1f0
      transition border-color .33s ease
    &:focus
      color #343434
      border-color $blue
      transition border-color .33s ease
    &.is-invalid
      color $red
      border-color $red
  .input-file
    display none
    &__wrap
      align-self center
  .personal-photo
    margin 0 auto 13px

.error-message
  text-align center
  font-size 11px
  letter-spacing 1.3px
  color $red
.change-pass-form
  max-width 310px
  width 100%

/*!===== Popup styles ====*/
.popup
  position fixed
  top 0
  left 0
  display none
  opacity 0
  z-index 9999
  align-items center
  justify-content center
  width 100%
  height 100vh
  background-color #fff
  -webkit-transition opacity 0.5s ease
  transition opacity 1.5s ease

  &--open
    display flex
    opacity 1
    transition opacity 1.5s ease

  &-container
    position relative
    background-color #fff

  .popup-close
    position absolute
    top 22px
    right 22px
    width 22px
    height 22px
    &:after
    &:before
      background-color $blue

.result-popup
  padding 30px 15px
  background rgba(32, 32, 32, 0.81)
  &-container
    max-width 390px
    padding 30px 45px 35px
    border-radius 12px
    font-size 14px
    font-weight 400
    text-align center

  &__heading
    margin 5px auto 0
    max-width 250px
    color #0071b9
    font-size 20px
    font-weight 500
    line-height 1.55

    & + *:not(.result-popup__pic)
        margin-top 15px

  &__btn
    margin-top 30px
    
/*!===== End Popup styles ====*/

+tablet()
  background-color orange

+mobile()
  background-color blue

/*!===== Rating page styles ====*/
.rating-page 
  font-size 14px
  font-weight 400
  &-header
    margin-bottom 60px
    max-width 680px
    * + *
      margin-top 30px

.rating
  &-filter
    display flex
    margin-bottom 20px
    &__sort
      display flex
      align-items center
      border-left 1px solid #e7e7e7
      margin-left 20px
      padding-left 10px
    &__btn
      position relative
      margin 0 5px
      width 20px
      height 20px
      
      &.is-top:after
        top 13px
      &.is-bottom:after
        top 9px
      &:after
        right 5px
  &-list
    margin-bottom 60px
    &__result
      flex-shrink 0
      margin-left 15px
    &__item
    &__user
      display flex
      align-items center
    &__item
      position relative
      justify-content space-between
      padding 12px 40px 12px 80px
      counter-increment: item;
      border-bottom 1px solid #e7e7e7
      &--user
        background-color #0068a1
        color #fff
        .rating-list__score 
          font-size 16px
        .rating-list__name
          font-weight 500
      &:first-child
        border-top 1px solid #e7e7e7
    &__count
      position absolute
      left 0
      top 0
      display flex
      align-items center
      justify-content center
      width 80px
      height 100%
      font-weight 700
      
    &__pic
      margin-right 22px
      width 41px
      min-width 41px
      height 41px
    &__score
      font-weight 500

/*!===== end Rating Page styles ====*/
/*!===== Header styles ====*/
.header
  position fixed
  top 0
  width 100%
  height 75px
  color #fff
  z-index 999
  transition all .33s ease
  background: $blue;
  &-wrapper 
    display flex
    justify-content space-between
    margin 0 auto
    max-width 1280px
    width 100%
    padding 0 
    transition all .33s ease

  &--scroll
    height: 75px;
    box-shadow: 1px 2px 6px 0 rgba(0, 0, 0, 0.15);
    background-color $blue
    transition all .33s ease
    .main-menu  
      border-bottom none
      &__link
        color $white
        transition color .33s ease
    .profile
      transform translate(-50px, 13px)
      transition transform .33s ease
    .logo-static
      display none
      transform scale(0)
    .logo-scroll
      visibility visible
      transform scale(1)
      transition transform .33s ease
  

  &--secondary
    .main-menu
      border-bottom-color rgba(57, 57, 57, 0.2)
      &__link
        color $white

.main-nav
  justify-content space-between
  
.logo
  &-wrap
    display flex
    align-items center
    transform translate(51px, 0)
    transition transform .33s ease
  &-link
    display block
    width 121px

    img
      width 100%

  &-static
    display block
    transform scale(1)
    transition transform .33s ease
    visibility visible
  &-scroll
    transform scale(0)
    visibility hidden

.main-menu
  position relative
  display flex
  margin 0 15px

  &__item 
    & + &
      margin-left 50px
  
  &__link
    position relative
    display flex
    align-items center
    justify-content center
    height 75px
    color #fff
    font-size 13px
    line-height 17px
    transition color .33s ease
    transition background-color .4s ease
    &:after
      content ''
      position absolute
      bottom 0px
      left 0
      width 100%
      height 3px
      background-color transprent
      transition background .33s ease
    &:hover:after
    &_active:after
    &--active:after
      background-color $white
      transition background .33s ease

.mobile-nav
  position relative
  display none
  width 30px
  height 30px
  background-color transparent

  span
    display block
    height 3px
    width 100%
    border-radius 6px
    background-color #fff

  span:not(:last-child)
    margin-bottom 4px

  &--open span
    position absolute
    display none
    transition transform 0.3s ease

    &:first-child
      display block
      transform rotate(45deg)

    &:last-child
      display block
      transform rotate(-45deg)

.profile
  position relative
  min-width 241px
  transform translate(-50px, 13px)
  transition transform .33s ease
  &-info-list
    padding-bottom 15px
    color $blue
  &-item
    position absolute
    left 0
    top 0
    width: 214px;
    border-radius 6px
    background: $white;
    box-shadow 0 1px 3px 0 rgba(0, 0, 0, 0.5)
    a
      color $blue
  &-user
    display flex
    align-items center
    padding 10px 0
    height 50px
    &__pic
      margin 0 6px 0 -10px
      width 35px
      height 35px
    &__link
      color $blue
      padding-right: 10px
      &:after
        border-left-color $blue

  &__btn
    flex-grow 3
    margin-left 10px
    height 26px
    min-width 32px
    border none
    border-left 1px solid rgba(0, 113, 185, 0.35)
    background transparent
    cursor pointer
    transition fill .33s ease

    svg path
      fill: $blue

  &-info
    width 100%
    font-size 13px
    line-height 19px
    font-weight 400
    display none
    &--open
      display block

    &__item
      position relative
      padding 5px 25px
      display flex
      justify-content space-between
      &:before
        content ''
        position absolute
        left -9px
        top 6px
        display block
        width 18px
        height 18px
        border-radius 50%
        box-shadow 0 1px 3px 0 rgba(0, 0, 0, 0.29)
        background-color #fff

      & + &
        margin-top 5px

      &:not(.profile-info__item--attention):after
        content ''
        position absolute
        top 13px
        left -2px
        width 4px
        height 4px
        border-radius 50%
        background-color $blue

      &--attention:before
        content '!'
        font-size 13px
        color $red
        text-align center
        font-weight 900

      a.notification-close
        color $black
        cursor pointer
    &__link
      margin-top -5px
      bottom 0
      left 50%
      transform translate(-50%, 50%)
/*!===== end Header styles ====*/

html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
abbr,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
samp,
small,
strong,
sub,
sup,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary,
time,
mark,
audio,
video {
  background: transparent;
  border: 0;
  font-size: 100%;
  margin: 0;
  outline: 0;
  padding: 0;
  vertical-align: baseline;
}
body {
  line-height: 1;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
nav ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}
a {
  background: transparent;
  font-size: 100%;
  margin: 0;
  padding: 0;
  vertical-align: baseline;
  outline: 0;
}
button {
  outline: 0;
}
ins {
  background-color: #ff9;
  color: #000;
  text-decoration: none;
}
mark {
  background-color: #ff9;
  color: #000;
  font-style: italic;
  font-weight: bold;
}
del {
  text-decoration: line-through;
}
abbr[title],
dfn[title] {
  border-bottom: 1px dotted;
  cursor: help;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
hr {
  border: 0;
  border-top: 1px solid #ccc;
  display: block;
  height: 1px;
  margin: 1em 0;
  padding: 0;
}
input,
select {
  vertical-align: middle;
}
*,
*:before,
*:after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
@font-face {
  font-family: 'Centrale';
  src: url("../fonts/cenrale-regular.woff2") format('woff2'), url("../fonts/cenrale-regular.woff") format('woff'), url("../fonts/cenrale-regular.svg#centrale_sansbook") format('svg');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Centrale';
  src: url("../fonts/centrale-bold.woff2") format('woff2'), url("../fonts/centrale-bold.woff") format('woff'), url("../fonts/centrale-bold.svg#centrale_sansbold") format('svg');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'Centrale';
  src: url("../fonts/centrale-light.woff2") format('woff2'), url("../fonts/centrale-light.woff") format('woff'), url("../fonts/centrale-light.svg#centrale_sanslight") format('svg');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'Centrale';
  src: url("../fonts/centrale-medium.woff2") format('woff2'), url("../fonts/centrale-medium.woff") format('woff'), url("../fonts/centrale-medium.svg#centrale_sansmedium") format('svg');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'Centrale';
  src: url("../fonts/centrale-thin.woff2") format('woff2'), url("../fonts/centrale-thin.woff") format('woff'), url("../fonts/centrale-thin.svg#centrale_sansthin") format('svg');
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: 'Centrale';
  src: url("../fonts/centrale-xbold.woff2") format('woff2'), url("../fonts/centrale-xbold.woff") format('woff'), url("../fonts/centrale-xbold.svg#centrale_sansxbold") format('svg');
  font-weight: 900;
  font-style: normal;
}
body {
  padding-top: 120px;
  font: 16px/22px 'Centrale', sans-serif;
  font-weight: 500;
  background-color: #fff;
  color: #393939;
}
.body-noscroll {
  overflow: hidden;
}
a {
  text-decoration: none;
}
ul {
  list-style-type: none;
}
button {
  outline: none;
  border: none;
  background: transparent;
  cursor: pointer;
}
input,
textarea {
  border: none;
  border-radius: 0;
  outline: none;
}
input:not([type=checkbox]):not([type=radio]),
textarea {
  appearance: none;
}
.wrapper {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 100%;
  max-width: 950px;
}
.wrapper--secondary {
  max-width: 790px;
}
.wrapper--inner {
  max-width: 711px;
}
.main-heading {
  font-size: 55px;
  line-height: 54px;
  letter-spacing: 1.3px;
  font-weight: 900;
}
.section-heading {
  color: #0071b9;
  text-align: center;
  font-size: 50px;
  line-height: 54px;
  font-weight: 300;
}
.article-heading {
  color: #0071b9;
  font-size: 35px;
  line-height: 42px;
  font-weight: 400;
}
.news-heading {
  color: #0071b9;
  font-size: 18px;
  line-height: 31px;
  font-weight: 500;
}
.small-text {
  font-size: 11px;
}
.btn-wrap {
  display: flex;
}
.btn-wrap--centered {
  align-items: center;
  justify-content: center;
  text-align: center;
}
.link,
.btn {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  line-height: 15px;
  font-weight: 700;
  transition: all 0.33s ease;
  cursor: pointer;
}
.link:after,
.btn:after {
  content: '';
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  display: block;
  width: 0;
  height: 0;
  border-top: 3px solid transparent;
  border-bottom: 3px solid transparent;
  transition: all 0.33s ease;
}
.link:hover,
.btn:hover {
  transition: all 0.33s ease;
}
.btn {
  height: 41px;
  padding: 5px 37px 5px 20px;
  border-radius: 6px;
}
.btn:after {
  right: 20px;
}
.btn--bordered {
  color: #0071b9;
  background-color: #fff;
  border: 1px solid transparent;
  box-shadow: 0 1px 3px 0 rgba(0,0,0,0.29);
}
.btn--bordered:after {
  border-left: 3px solid #0071b9;
}
.btn--bordered:hover {
  border-color: #0087de;
  color: #0087de;
}
.btn--bordered:hover:after {
  border-left: 3px solid #0087de;
}
.btn--filled {
  color: #fff;
  background-color: #0071b9;
  transition-property: background-color !important;
}
.btn--filled:after {
  border-left: 3px solid #fff;
}
.btn--filled:hover {
  background-color: #0087de;
}
.link {
  padding-right: 12px;
  color: #0071b9;
}
.link:after {
  right: 0;
  border-left: 3px solid #0071b9;
}
.link:hover {
  color: #0087de;
}
.link:hover:after {
  border-left-color: #0087de;
}
.link--back {
  margin-left: -12px;
  padding-left: 12px;
}
.link--back:after {
  left: 0;
  right: auto;
  border-left: none;
  border-right: 3px solid #0071b9;
}
.link--back:hover:after {
  border-right-color: #0087de;
}
.link--down {
  padding-right: 20px;
}
.link--down:after {
  border-left: 3px solid transparent;
  border-right: 3px solid transparent;
  border-top: 3px solid #0071b9;
}
.circle-img {
  border-radius: 50%;
  box-shadow: 0 1px 3px 0 rgba(0,0,0,0.29);
  border: solid 2px #fff;
}
.circle-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  font-family: 'object-fit: cover';
  border-radius: 50%;
}
.tag {
  position: relative;
  display: inline-flex;
  align-items: center;
  margin-bottom: 30px;
  padding: 5px 11px 5px 23px;
  min-height: 24px;
  border-radius: 4px;
  border: 1px solid #0071b9;
  font-size: 11px;
  line-height: 11px;
  transition: all 0.33s ease;
}
.tag:before {
  content: '';
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: #0071b9;
  transition: all 0.33s ease;
}
.tag--secondary {
  border-color: #fff;
}
.tag--secondary:before {
  background-color: #fff;
}
.tag:hover:not(.tag--secondary) {
  color: #0087de;
  border-color: #0087de;
  transition: all 0.33s ease;
}
.tag:hover:not(.tag--secondary):before {
  background-color: #0087de;
  transition: all 0.33s ease;
}
.is-invisible {
  opacity: 0;
  pointer-events: none;
  visibility: hidden;
}
.is-right:after,
.is-left:after,
.is-bottom:after,
.is-top:after {
  content: '';
  position: absolute;
  top: 50%;
  width: 8px;
  height: 8px;
  transform: translateY(-50%) rotate(45deg);
  border: solid #0071b9;
}
.is-right:after {
  border-width: 2px 2px 0 0;
}
.is-left:after {
  border-width: 0 0 2px 2px;
}
.is-bottom:after {
  border-width: 0 2px 2px 0;
}
.is-top:after {
  border-width: 2px 0 0 2px;
}
.close-ico {
  cursor: pointer;
}
.close-ico:before,
.close-ico:after {
  content: ' ';
  position: absolute;
  top: 50%;
  left: 50%;
  height: 12px;
  width: 2px;
  transition: background 0.33s ease;
}
.close-ico:hover:before,
.close-ico:hover:after {
  background-color: #0087de;
  transition: background 0.33s ease;
}
.close-ico:before {
  transform: translate(-50%, -50%) rotate(45deg);
}
.close-ico:after {
  transform: translate(-50%, -50%) rotate(-45deg);
}
.intro-section {
  position: relative;
  display: flex;
  align-items: center;
  margin-top: -120px;
  height: 100vh;
  width: 100%;
  padding: 120px 0;
  color: #fff;
}
.intro-heading {
  margin-bottom: 18px;
}
.intro-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}
.intro-bg__img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  font-family: 'object-fit: cover';
}
.intro-content {
  max-width: 510px;
}
.intro-content p + p {
  margin-top: 18px;
}
.intro-content p + .btn {
  margin-top: 45px;
}
.main-section {
  padding: 110px 0;
}
.section-header {
  margin-bottom: 80px;
  text-align: center;
}
.section-header * + * {
  margin-top: 25px;
}
.md-visible {
  display: none;
  margin-top: 60px;
}
.questions {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -5px;
}
.questions-section {
  background-color: #0071b9;
}
.questions-section__link {
  color: #fff;
}
.questions-section__link:after {
  border-left-color: #fff;
}
.questions-section .section-heading {
  color: #fff;
}
.questions-item {
  position: relative;
  margin: 5px;
  max-width: 310px;
  width: 100%;
  padding: 22px 21px 90px;
  min-height: 213px;
  object-fit: contain;
  border-radius: 12px;
  background-color: #fff;
  box-shadow: 1px 2px 6px 0 rgba(0,0,0,0.16);
}
.questions-item:last-child {
  margin-right: auto;
}
.questions-item__info {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}
.questions-item__pic {
  position: relative;
  margin-right: 18px;
  width: 41px;
  height: 41px;
}
.questions-item__name {
  font-size: 13px;
  font-weight: 700;
}
.questions-item__date {
  margin-bottom: 5px;
  font-size: 11px;
  line-height: 17px;
}
.questions-item__content {
  margin-bottom: 25px;
}
.questions-item__text {
  color: #0071b9;
}
.questions-item .btn {
  position: absolute;
  left: 21px;
  bottom: 33px;
}
.is-admin:after {
  content: '';
  position: absolute;
  top: 0;
  right: -10px;
  width: 18px;
  height: 18px;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxOCIgaGVpZ2h0PSIxOCIgdmlld0JveD0iMCAwIDE4IDE4Ij4KICAgIDxnIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPGNpcmNsZSBjeD0iOSIgY3k9IjkiIHI9IjguNSIgZmlsbD0iI0YwMCIvPgogICAgICAgIDxwYXRoIGZpbGw9IiNGRkYiIGQ9Ik05IDExLjQ2M0w2LjA3NiAxM2wuNTU4LTMuMjU2LTIuMzY2LTIuMzA2IDMuMjctLjQ3NUw5IDRsMS40NjIgMi45NjMgMy4yNy40NzUtMi4zNjYgMi4zMDYuNTU4IDMuMjU2eiIvPgogICAgPC9nPgo8L3N2Zz4K");
}
/*===== Form styles ====*/
.form {
  font-size: 14px;
  font-weight: 400;
}
.form-heading {
  margin-bottom: 35px;
  text-align: center;
}
.form-personal__photo {
  margin-bottom: 50px;
}
.form-row {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.form-row + .form-row {
  margin-top: 13px;
}
.form-row + .form-row--submit {
  margin-top: 35px;
}
.form-row--centered {
  text-align: center;
}
.form-row--message {
  min-height: 22px;
}
.form-row--submit {
  flex-direction: row;
  justify-content: center;
}
.form-required__field {
  font-size: 11px;
  font-weight: 400;
}
.form-input {
  margin-top: 6px;
  width: 100%;
  height: 45px;
  padding: 10px 18px;
  border-radius: 6px;
  border: solid 1px #d4d4d4;
  transition: border-color 0.33s ease;
}
.form-input:hover {
  border-color: #79c1f0;
  transition: border-color 0.33s ease;
}
.form-input:focus {
  color: #343434;
  border-color: #0071b9;
  transition: border-color 0.33s ease;
}
.form-input.is-invalid {
  color: #f00;
  border-color: #f00;
}
.form .input-file {
  display: none;
}
.form .input-file__wrap {
  align-self: center;
}
.form .personal-photo {
  margin: 0 auto 13px;
}
.error-message {
  text-align: center;
  font-size: 11px;
  letter-spacing: 1.3px;
  color: #f00;
}
.change-pass-form {
  max-width: 310px;
  width: 100%;
}
/*===== Popup styles ====*/
.popup {
  position: fixed;
  top: 0;
  left: 0;
  display: none;
  opacity: 0;
  z-index: 9999;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  background-color: #fff;
  -webkit-transition: opacity 0.5s ease;
  transition: opacity 1.5s ease;
}
.popup--open {
  display: flex;
  opacity: 1;
  transition: opacity 1.5s ease;
}
.popup-container {
  position: relative;
  background-color: #fff;
}
.popup .popup-close {
  position: absolute;
  top: 22px;
  right: 22px;
  width: 22px;
  height: 22px;
}
.popup .popup-close:after,
.popup .popup-close:before {
  background-color: #0071b9;
}
.result-popup {
  padding: 30px 15px;
  background: rgba(32,32,32,0.81);
}
.result-popup-container {
  max-width: 390px;
  padding: 30px 45px 35px;
  border-radius: 12px;
  font-size: 14px;
  font-weight: 400;
  text-align: center;
}
.result-popup__heading {
  margin: 5px auto 0;
  max-width: 250px;
  color: #0071b9;
  font-size: 20px;
  font-weight: 500;
  line-height: 1.55;
}
.result-popup__heading + *:not(.result-popup__pic) {
  margin-top: 15px;
}
.result-popup__btn {
  margin-top: 30px;
}
/*===== End Popup styles ====*/
@media only screen and (min-width: 768px) and (max-width: 1023px) {
}
@media only screen and (max-width: 767px) {
}
/*===== Header styles ====*/
.header {
  position: fixed;
  top: 0;
  width: 100%;
  height: 75px;
  color: #fff;
  z-index: 999;
  transition: all 0.33s ease;
  background: #0071b9;
}
.header-wrapper {
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  max-width: 1280px;
  width: 100%;
  padding: 0;
  transition: all 0.33s ease;
}
.header--scroll {
  height: 75px;
  box-shadow: 1px 2px 6px 0 rgba(0,0,0,0.15);
  background-color: #0071b9;
  transition: all 0.33s ease;
}
.header--scroll .main-menu {
  border-bottom: none;
}
.header--scroll .main-menu__link {
  color: #fff;
  transition: color 0.33s ease;
}
.header--scroll .profile {
  transform: translate(-50px, 13px);
  transition: transform 0.33s ease;
}
.header--scroll .logo-static {
  display: none;
  transform: scale(0);
}
.header--scroll .logo-scroll {
  visibility: visible;
  transform: scale(1);
  transition: transform 0.33s ease;
}
.header--secondary .main-menu {
  border-bottom-color: rgba(57,57,57,0.2);
}
.header--secondary .main-menu__link {
  color: #fff;
}
.main-nav {
  justify-content: space-between;
}
.logo-wrap {
  display: flex;
  align-items: center;
  transform: translate(51px, 0);
  transition: transform 0.33s ease;
}
.logo-link {
  display: block;
  width: 121px;
}
.logo-link img {
  width: 100%;
}
.logo-static {
  display: block;
  transform: scale(1);
  transition: transform 0.33s ease;
  visibility: visible;
}
.logo-scroll {
  transform: scale(0);
  visibility: hidden;
}
.main-menu {
  position: relative;
  display: flex;
  margin: 0 15px;
}
.main-menu__item + .main-menu__item {
  margin-left: 50px;
}
.main-menu__link {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 75px;
  color: #fff;
  font-size: 13px;
  line-height: 17px;
  transition: color 0.33s ease;
  transition: background-color 0.4s ease;
}
.main-menu__link:after {
  content: '';
  position: absolute;
  bottom: 0px;
  left: 0;
  width: 100%;
  height: 3px;
  background-color: transprent;
  transition: background 0.33s ease;
}
.main-menu__link:hover:after,
.main-menu__link_active:after,
.main-menu__link--active:after {
  background-color: #fff;
  transition: background 0.33s ease;
}
.mobile-nav {
  position: relative;
  display: none;
  width: 30px;
  height: 30px;
  background-color: transparent;
}
.mobile-nav span {
  display: block;
  height: 3px;
  width: 100%;
  border-radius: 6px;
  background-color: #fff;
}
.mobile-nav span:not(:last-child) {
  margin-bottom: 4px;
}
.mobile-nav--open span {
  position: absolute;
  display: none;
  transition: transform 0.3s ease;
}
.mobile-nav--open span:first-child {
  display: block;
  transform: rotate(45deg);
}
.mobile-nav--open span:last-child {
  display: block;
  transform: rotate(-45deg);
}
.profile {
  position: relative;
  min-width: 241px;
  transform: translate(-50px, 13px);
  transition: transform 0.33s ease;
}
.profile-info-list {
  padding-bottom: 15px;
  color: #0071b9;
}
.profile-item {
  position: absolute;
  left: 0;
  top: 0;
  width: 214px;
  border-radius: 6px;
  background: #fff;
  box-shadow: 0 1px 3px 0 rgba(0,0,0,0.5);
}
.profile-item a {
  color: #0071b9;
}
.profile-user {
  display: flex;
  align-items: center;
  padding: 10px 0;
  height: 50px;
}
.profile-user__pic {
  margin: 0 6px 0 -10px;
  width: 35px;
  height: 35px;
}
.profile-user__link {
  color: #0071b9;
  padding-right: 10px;
}
.profile-user__link:after {
  border-left-color: #0071b9;
}
.profile__btn {
  flex-grow: 3;
  margin-left: 10px;
  height: 26px;
  min-width: 32px;
  border: none;
  border-left: 1px solid rgba(0,113,185,0.35);
  background: transparent;
  cursor: pointer;
  transition: fill 0.33s ease;
}
.profile__btn svg path {
  fill: #0071b9;
}
.profile-info {
  width: 100%;
  font-size: 13px;
  line-height: 19px;
  font-weight: 400;
  display: none;
}
.profile-info--open {
  display: block;
}
.profile-info__item {
  position: relative;
  padding: 5px 25px;
  display: flex;
  justify-content: space-between;
}
.profile-info__item:before {
  content: '';
  position: absolute;
  left: -9px;
  top: 6px;
  display: block;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  box-shadow: 0 1px 3px 0 rgba(0,0,0,0.29);
  background-color: #fff;
}
.profile-info__item + .profile-info__item {
  margin-top: 5px;
}
.profile-info__item:not(.profile-info__item--attention):after {
  content: '';
  position: absolute;
  top: 13px;
  left: -2px;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: #0071b9;
}
.profile-info__item--attention:before {
  content: '!';
  font-size: 13px;
  color: #f00;
  text-align: center;
  font-weight: 900;
}
.profile-info__item a.notification-close {
  color: #393939;
  cursor: pointer;
}
.profile-info__link {
  margin-top: -5px;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 50%);
}
/*===== end Header styles ====*/
/*===== studies-list styles ====*/
.studies {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -5px;
}
.studies-item {
  position: relative;
  margin: 5px;
  max-width: 310px;
  min-width: 260px;
  padding: 27px 0 90px;
  min-height: 409px;
  object-fit: contain;
  border-radius: 12px;
  background-color: #fff;
  box-shadow: 1px 2px 6px 0 rgba(0,0,0,0.16);
}
.studies-item:last-child {
  margin-right: auto;
}
.studies-item__tag {
  margin-left: 25px;
  margin-right: 25px;
}
.studies-item__pic {
  margin-bottom: 25px;
  width: 100%;
  height: 164px;
}
.studies-item__img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  font-family: 'object-fit: cover';
}
.studies-item__content {
  padding: 0 27px;
  color: #0071b9;
}
.studies-item__btn {
  position: absolute;
  bottom: 33px;
  left: 27px;
}
/*===== end studies-list styles ====*/
/*===== Footer styles ====*/
.footer {
  position: relative;
  padding: 30px 0;
  min-height: 220px;
  color: #fff;
  background-color: #0071b9;
}
.footer div + div:not(.footer-info__wrap) {
  margin-top: 30px;
}
.footer-logo__link {
  margin: 0 auto;
  display: block;
  height: 53px;
  width: 39px;
}
.footer-copyright {
  text-align: center;
}
.footer-copyright__text {
  font-size: 11px;
  line-height: 17px;
  font-weight: 400;
}
.footer-socials {
  display: flex;
  align-items: center;
  justify-content: center;
}
.footer-socials__link {
  margin: 0 10px;
  padding: 5px;
  height: 28px;
}
.footer-socials__link svg path {
  transition: fill 0.33s ease;
}
.footer-socials__link:hover svg path {
  fill: #61beff;
  transition: fill 0.33s ease;
}
.footer-info {
  display: flex;
  justify-content: space-between;
}
.footer-info__wrap {
  width: 33.333333%;
  text-align: center;
}
.footer-info__wrap.is-left {
  text-align: left;
}
.footer-info__wrap.is-right {
  text-align: right;
}
.footer-info__link {
  font-size: 11px;
  line-height: 17px;
  color: #fff;
  text-decoration: underline;
  font-weight: 400;
}
/*===== end Footer styles ====*/
/*===== Authorization page styles ====*/
.authorization {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -120px;
  min-height: 100vh;
  width: 100%;
  padding: 60px 15px;
  font-size: 14px;
  background-color: #fff;
}
.authorization-container {
  max-width: 550px;
}
.authorization-form {
  margin: 50px auto;
  max-width: 310px;
}
.authorization-form .remember-pass {
  align-self: flex-end;
}
.authorization-form .form-row--submit {
  justify-content: space-between;
  align-items: center;
}
.authorization-step.is-current {
  display: block;
}
.authorization-steps {
  display: flex;
  align-items: center;
  justify-content: center;
}
.authorization-steps__item {
  margin: 0 10px;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 1px solid #d4d4d4;
  color: #d4d4d4;
  text-align: center;
}
.authorization-steps__item.is-current {
  color: #0071b9;
  border-color: #0071b9;
}
/*===== end Authorization Page styles ====*/
/*===== Studies Page styles ====*/
.page {
  padding: 80px 0 120px;
}
.studies-page-header {
  margin-bottom: 48px;
}
.studies-page-header * + * {
  margin-top: 18px;
}
.studies-select {
  display: none;
  margin: 0 auto 30px;
  width: 300px;
  max-width: 100%;
}
.filter-list {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -5px 40px;
}
.filter-list__item {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 5px;
  padding: 20px 15px;
  width: 230px;
  height: 80px;
  border-radius: 12px;
  font-size: 14px;
  background-color: #fff;
  border: 1px solid transparent;
  box-shadow: 0 2px 6px 0 rgba(0,0,0,0.15);
  cursor: pointer;
  transition: border-color 0.33s ease;
}
.filter-list__item:hover {
  border: 1px solid #2da492;
  transition: border-color 0.33s ease;
}
.filter-list__item.is--active {
  color: #fff;
  background-image: linear-gradient(306deg, #7dc7c6, #0b4f4e);
}
.filter-list__item.is--season:after {
  content: '';
  position: absolute;
  top: 10px;
  right: 10px;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxOCIgaGVpZ2h0PSIxOCIgdmlld0JveD0iMCAwIDE4IDE4Ij4KICAgIDxnIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPGNpcmNsZSBjeD0iOSIgY3k9IjkiIHI9IjguNSIgZmlsbD0iI0YwMCIvPgogICAgICAgIDxwYXRoIGZpbGw9IiNGRkYiIGQ9Ik05IDExLjQ2M0w2LjA3NiAxM2wuNTU4LTMuMjU2LTIuMzY2LTIuMzA2IDMuMjctLjQ3NUw5IDRsMS40NjIgMi45NjMgMy4yNy40NzUtMi4zNjYgMi4zMDYuNTU4IDMuMjU2eiIvPgogICAgPC9nPgo8L3N2Zz4K");
}
/*===== end Studies Page styles ====*/
/*===== Article styles, Contests and News Page styles ====*/
.contests-page h2 {
  margin-bottom: 40px;
}
.contests-page .tag {
  pointer-events: none;
}
.top-news {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 90px;
}
.top-news-pic {
  max-width: 510px;
  width: 55%;
  min-width: 350px;
  height: 100%;
}
.top-news__img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  font-family: 'object-fit: cover';
}
.top-news-content {
  width: 45%;
  max-width: 400px;
  padding: 30px 15px;
}
.top-news__heading {
  margin-bottom: 30px;
}
.btn-wrap + .article {
  margin-top: 60px;
}
.article + .btn-wrap {
  margin-top: 60px;
}
.article-page {
  font-weight: 400;
  font-size: 14px;
}
.article-page .wrapper > * + * {
  margin-top: 60px;
}
@media only screen and (max-width: 1023px) {
  .article-page .wrapper > * + * {
    margin-top: 45px;
  }
}
.article-content > img {
  max-width: 100% !important;
  height: auto !important;
}
.article--contest .article-heading {
  margin-top: 0;
}
.article--contest .tag {
  display: none;
}
.article .tag {
  margin-bottom: 0;
}
.article-header {
  margin-bottom: 50px;
}
.article-header * + * {
  margin-top: 30px;
}
.article__date {
  font-size: 11px;
}
.article__title {
  font-size: 18px;
  line-height: 31px;
}
.article__img {
  max-width: 100%;
}
.article-content * + * {
  margin-top: 50px;
}
.article-list__item {
  position: relative;
  padding-left: 40px;
}
.article-list__item:before {
  content: '';
  position: absolute;
  left: 16px;
  top: 7px;
  width: 8px;
  height: 1px;
  background-color: #656565;
}
.article-list__item + .article-list__item {
  margin-top: 30px;
}
.article-slider {
  position: relative;
}
.article-slider__item {
  margin-top: 0;
}
.article-slider__img {
  width: 100%;
  object-fit: cover;
  font-family: 'object-fit: cover';
}
.article-slider .slick-arrow {
  position: absolute;
  top: 50%;
  display: block;
  margin-top: 0;
  width: 30px;
  height: 30px;
  cursor: pointer;
  background: transparent;
  font-size: 0;
  line-height: 0;
}
.article-slider .slick-arrow:after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 8px;
  height: 8px;
  transform: translate(-50%, -50%) rotate(45deg);
  border: solid #0071b9;
  transition: border-color 0.33s ease;
}
.article-slider .slick-arrow:hover {
  border-color: #0087de;
  transition: border-color 0.33s ease;
}
.article-slider .slick-prev {
  right: -50px;
}
.article-slider .slick-prev:after {
  border-width: 0 0 2px 2px;
}
.article-slider .slick-next {
  right: -83px;
}
.article-slider .slick-next:after {
  border-width: 2px 2px 0 0;
}
.article-slider__counter {
  position: absolute;
  right: -81px;
  top: 50%;
  transform: translate(0, -100%);
  margin-top: 0;
}
.article-slider__counter li {
  display: none;
  margin-top: 0;
  font-size: 28px;
  font-weight: 100;
  line-height: 28px;
  color: #0071b9;
}
.article-slider__counter li.slick-active {
  display: block;
}
.article-slider__counter li.slick-active:first-letter {
  color: #0071b9;
}
.article-slider__total {
  color: #a7a7a7;
}
.input-file {
  position: relative;
  padding-left: 30px;
  color: #0071b9;
  font-size: 13px;
  font-weight: 700;
  cursor: pointer;
}
.input-file__preview {
  position: relative;
  flex-shrink: 0;
  margin-right: 15px;
  width: 40px;
  height: 40px;
  border: 1px solid #0071b9;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNCIgaGVpZ2h0PSIxNCIgdmlld0JveD0iMCAwIDE0IDE0Ij4KICAgIDxnIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPHBhdGggc3Ryb2tlPSIjMDA3MUI5IiBzdHJva2Utd2lkdGg9IjIiIGQ9Ik0xIDcuMjQzdjVoMTJ2LTUiLz4KICAgICAgICA8cGF0aCBmaWxsPSIjRkZGIiBkPSJNNyA5LjI0M3YtOCA4eiIvPgogICAgICAgIDxwYXRoIHN0cm9rZT0iIzAwNzFCOSIgc3Ryb2tlLXdpZHRoPSIyIiBkPSJNNyA5LjI0M3YtOE00LjE3MSA0LjI0M2wyLjgzLTIuODMgMi44MjcgMi44MyIvPgogICAgPC9nPgo8L3N2Zz4K");
  background-repeat: no-repeat;
  background-position: center;
}
.input-file__preview.is-downloaded {
  background-size: 30%;
  background-position-x: 25px;
  background-position-y: 25px;
  background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/PjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+PHN2ZyB2ZXJzaW9uPSIxLjEiIGlkPSJDYXBhXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IiB3aWR0aD0iNDg5Ljc5M3B4IiBoZWlnaHQ9IjQ4OS43OTNweCIgdmlld0JveD0iMCAwIDQ4OS43OTMgNDg5Ljc5MyIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgNDg5Ljc5MyA0ODkuNzkzOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+PGc+PGc+PHBvbHlnb24gcG9pbnRzPSI0ODkuNzkzLDM1OS44MzIgNDQ0LjcxOSwzNTkuODMyIDQ0NC43MTksNDMxLjk0OCA0NS4wNzMsNDMxLjk0OCA0NS4wNzMsMzYxLjMzNCAwLDM2MS4zMzQgMCw0NzcuMDIxIDQ4OS43OTMsNDc3LjAyMSIgZmlsbD0nIzAwNzFiOScvPjxwb2x5Z29uIHBvaW50cz0iMTMxLjY3LDE3NC40NjIgNjkuMDY3LDE3NC40NjIgMjQ5LjYyOCwzOTguODk2IDQzMC4xODgsMTc0LjQ2MiAzNjcuNTg2LDE3NC40NjIgMzY3LjU4NiwxMi43NzEgMTMxLjY3LDEyLjc3MSAiIGZpbGw9JyMwMDcxYjknLz48L2c+PC9nPjxnPjwvZz48Zz48L2c+PGc+PC9nPjxnPjwvZz48Zz48L2c+PGc+PC9nPjxnPjwvZz48Zz48L2c+PGc+PC9nPjxnPjwvZz48Zz48L2c+PGc+PC9nPjxnPjwvZz48Zz48L2c+PGc+PC9nPjwvc3ZnPg==");
}
.input-file__preview img {
  position: relative;
  z-index: 2;
  width: 100%;
  height: 100% !important;
  object-fit: cover;
  font-family: 'object-fit: cover';
}
.input-file__name {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: block;
}
.input-file input {
  display: none;
}
.comments-form__row .input-file {
  display: flex;
  align-items: center;
  padding-left: 0;
}
.input-file-hint {
  font-size: 12px;
}
.input-file-hint.is-error {
  color: #f00;
}
.article + .article-page-section {
  margin-top: 50px;
}
.article-page-section + .article-page-section {
  margin-top: 70px;
}
.article-page-section >* + * {
  margin-top: 10px;
}
.article-page-section .article-heading + * {
  margin-top: 40px;
}
.article-user {
  padding: 22px 40px 60px 80px;
  border-radius: 12px;
  box-shadow: 1px 2px 6px 0 rgba(0,0,0,0.16);
}
.article-user__header,
.article-user__info {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.article-user__pic {
  margin: 0 20px 0 -61px;
  width: 41px;
  height: 41px;
}
.article-user__date {
  font-size: 11px;
}
.article-user__name {
  font-size: 13px;
  font-weight: 700;
}
.article-user__content {
  margin-top: 20px;
}
.article-user__content * + * {
  margin-top: 30px;
}
.article-user__content p {
  width: 100%;
}
.article-user__img {
  max-width: 50%;
}
.comments-form {
  display: flex;
  padding: 35px 50px 40px 30px;
  border-radius: 12px;
  box-shadow: 1px 2px 6px 0 rgba(0,0,0,0.16);
}
.comments-form--unboxed {
  padding: 0;
  border-radius: 0;
  box-shadow: none;
}
.comments-form__pic {
  flex-shrink: 0;
  margin-right: 30px;
  width: 60px;
  height: 60px;
}
.comments-form-item {
  width: 100%;
}
.comments-form__field {
  margin-bottom: 30px;
  min-height: 78px;
  width: 100%;
  padding: 15px;
  border: 1px solid #d9d9d9;
  border-radius: 6px;
  font-size: 14px;
  font-weight: 400;
  color: #393939;
}
.comments-form__row {
  margin-bottom: 15px;
}
.comments-form__row.is-space-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.comments-form__btns {
  display: flex;
  justify-content: flex-end;
}
.comments-form__btns button:not(:last-child) {
  margin-right: 30px;
}
textarea.error {
  margin-bottom: 0;
  border-color: #f00;
}
textarea.error + label.error {
  display: block;
  margin-bottom: 8px;
}
.question-form .comments-form__field {
  margin-bottom: 20px;
}
.question-form .comments-form__btns {
  margin-top: -32px;
}
/*===== end Article styles, Contests and News Page styles ====*/
/*===== Questions page styles ====*/
.page--questions {
  font-size: 14px;
}
.questions-header {
  display: flex;
  justify-content: space-between;
}
.questions-header + * {
  margin-top: 30px;
}
.questions-header-btn {
  flex-shrink: 0;
}
.questions-header-text {
  margin-right: 15px;
  max-width: 520px;
}
.questions-header-text * + * {
  margin-top: 30px;
}
.questions-header + .comments-form {
  display: none;
}
.questions-box {
  margin-top: 68px;
}
.questions-box-header {
  margin-bottom: 30px;
}
.questions-box__btn {
  margin-right: 30px;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.72;
  color: #6d6d6d;
  cursor: pointer;
}
.questions-box__btn.is-active {
  color: #0071b9;
}
.questions-box__btn:hover {
  color: #0087de;
}
.questions-list__item {
  border-bottom: 2px solid #e7e7e7;
}
.questions-list__item:first-child {
  border-top: 2px solid #e7e7e7;
}
.questions-list__item.is-open {
  background-color: #f9f9f9;
}
.questions-list__item.is-open .questions-list__toggle-button:after {
  transform: rotate(225deg);
}
.questions-list__item.is-open .questions-list__answers {
  display: block;
}
.questions-list__toggle-button {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  display: block;
  width: 60px;
  height: 100%;
  cursor: pointer;
  transition: background-color 0.2s;
}
.questions-list__toggle-button:hover {
  background-color: #f9f9f9;
}
.questions-list__toggle-button:after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  display: block;
  width: 10px;
  height: 10px;
  border: solid #0071b9;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}
.questions-list__header,
.questions-list__info {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.questions-list__pic {
  position: relative;
  margin: 0 20px 0 10px;
  width: 41px;
  height: 41px;
}
.questions-list__date {
  font-size: 11px;
}
.questions-list__name {
  font-size: 13px;
  font-weight: 700;
}
.questions-list__content {
  margin-top: 20px;
  padding-left: 71px;
  font-size: 14px;
}
.questions-list__content * + * {
  margin-top: 30px;
}
.questions-list__content p {
  width: 100%;
}
.questions-list__img {
  max-width: 50%;
}
.questions-list__question {
  position: relative;
  padding: 30px 80px 40px 0;
  background-color: #fff;
}
.questions-list__answers {
  display: none;
}
.questions-list__answers .comments-form {
  padding: 40px 80px 30px 110px;
}
.questions-list__answer {
  position: relative;
  padding: 30px 80px 20px 40px;
  background-color: #f9f9f9;
  cursor: pointer;
}
.questions-list + .btn-wrap {
  margin-top: 60px;
}
/*===== end Questions Page styles ====*/
/*===== Profile page styles ====*/
.personal-page__heading {
  margin-bottom: 30px;
}
.personal-page__subheading {
  font-size: 18px;
  line-height: 30px;
  font-weight: 500;
}
.personal-page-tabs {
  display: none;
}
.personal-page__exit {
  margin-top: 35px;
}
.notification {
  font-size: 14px;
  font-weight: 400;
}
.notification > * + * {
  margin-top: 40px;
}
.notification-block {
  position: relative;
  padding: 23px 66px 23px 91px;
  color: #fff;
  background-color: #0068a1;
}
.notification-block a {
  color: #fff;
}
.notification-block--close {
  display: none;
}
.notification-block--close + * {
  margin-top: 0;
}
.notification-block:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 66px;
  height: 100%;
  background: center no-repeat url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI0IiBoZWlnaHQ9IjE2IiB2aWV3Qm94PSIwIDAgNCAxNiI+CiAgICA8ZyBmaWxsPSIjRkZGIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPgogICAgICAgIDxwYXRoIGQ9Ik0wIDEwaDRWMEgwek0wIDE2aDR2LTRIMHoiLz4KICAgIDwvZz4KPC9zdmc+Cg==");
  background-color: #004179;
}
.notification-block__close {
  position: absolute;
  right: 30px;
  top: 50%;
  transform: translateY(-50%);
  width: 22px;
  height: 22px;
}
.notification-block__close:before,
.notification-block__close:after {
  background-color: #fff;
}
.notification-atestation {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 21px 40px;
  border: solid 1px #e7e7e7;
}
.notification-atestation-text {
  margin-right: 15px;
  max-width: 570px;
}
.notification-atestation-text * + * {
  margin-top: 15px;
}
.notification-atestation-btn {
  flex-shrink: 0;
}
.notification + .personal {
  margin-top: 65px;
}
.personal-photo {
  margin-bottom: 18px;
  width: 151px;
  height: 151px;
  background: #e7e7e7 center no-repeat;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0OCA1NCI+PGRlZnM+PHN0eWxlPi5he2ZpbGw6I2ZmZjt9PC9zdHlsZT48L2RlZnM+PHRpdGxlPnVzZXItcGljLWJnMjwvdGl0bGU+PHBhdGggY2xhc3M9ImEiIGQ9Ik04Niw4NGMwLTEyLTUuMTMtMjIuNDYtMTIuNjctMjcuNzdhMTQuNDUsMTQuNDUsMCwwLDEtMjIuNjcsMEM0My4xMyw2MS41NCwzOCw3MiwzOCw4NCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTM4IC0zMCkiLz48cGF0aCBjbGFzcz0iYSIgZD0iTTc1LjMzLDQzLjVBMTMuMzMsMTMuMzMsMCwxLDEsNjIsMzAsMTMuNDIsMTMuNDIsMCwwLDEsNzUuMzMsNDMuNSIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTM4IC0zMCkiLz48L3N2Zz4=");
  background-size: 50px;
}
.personal .link input {
  display: none;
}
.personal-information,
.personal-block {
  display: flex;
}
.personal-information + .personal-block {
  margin-top: 100px;
}
.personal-left-block {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  flex-shrink: 0;
  width: 280px;
  padding-right: 20px;
}
.personal-right-block {
  display: flex;
  flex-wrap: wrap;
  flex-grow: 2;
}
.personal-img-form {
  text-align: center;
}
.personal-info__descr {
  margin-bottom: 13px;
  font-size: 11px;
  line-height: 24px;
  color: #868686;
}
.personal-info__row {
  margin-bottom: 35px;
  width: 50%;
  padding-right: 15px;
}
.personal-info__btns {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-grow: 2;
  padding: 25px 40px 0 0;
  border-top: solid 1px #e7e7e7;
}
.personal-rating > * + * {
  margin-top: 40px;
}
.personal-rating__score {
  font-size: 48px;
  font-weight: 300;
  line-height: 1.13;
  text-align: center;
  color: #0071b9;
}
.personal-rating__descr {
  display: block;
  text-align: center;
  font-size: 14px;
  font-weight: 400;
}
.personal-achievements {
  flex-direction: column;
  align-items: center;
  border-left: 1px solid #e7e7e7;
}
.personal-achievements >* + * {
  margin-top: 30px;
}
.achievements-list {
  display: flex;
  flex-wrap: wrap;
  max-width: 500px;
}
.achievements-list__item {
  margin: 20px 25px;
  width: 75px;
  height: 75px;
}
.icon-info {
  display: inline-block;
  width: 12px;
  height: 12px;
  background: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMiIgaGVpZ2h0PSIxMiIgdmlld0JveD0iMCAwIDEyIDEyIj4KICAgIDxnIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgc3Ryb2tlPSIjMkE5RDhEIj4KICAgICAgICA8cGF0aCBmaWxsPSIjMkE5RDhEIiBzdHJva2Utd2lkdGg9Ii4zIiBkPSJNNi40MzUgNS4yOTdWOWgtLjg5NlY1LjI5N2guODk2ek01Ljk4IDMuNDIxYy4xNjggMCAuMzA0LjA1Mi40MS4xNThhLjUyOC41MjggMCAwIDEgLjE1Ny4zODguNTE2LjUxNiAwIDAgMS0uMTU3LjM4NS41Ni41NiAwIDAgMS0uNDEuMTU0LjU1Mi41NTIgMCAwIDEtLjQwMy0uMTU0LjUxNi41MTYgMCAwIDEtLjE1Ny0uMzg1YzAtLjE1NC4wNTItLjI4My4xNTctLjM4OWEuNTQ1LjU0NSAwIDAgMSAuNDAzLS4xNTd6Ii8+CiAgICAgICAgPGNpcmNsZSBjeD0iNiIgY3k9IjYiIHI9IjUuNSIvPgogICAgPC9nPgo8L3N2Zz4K");
}
.achievements-list-extended {
  width: 100%;
  margin-bottom: -20px;
}
.achievements-list-extended__item {
  position: relative;
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 24px;
  font-weight: normal;
  margin-bottom: 20px;
}
.achievements-list-extended__item .bubble {
  left: 53px;
  bottom: 53px;
}
.achievements-list-extended__image {
  width: 78px;
  height: 78px;
  border: 2px solid #fff;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.22);
  border-radius: 50%;
  overflow: hidden;
  background-size: cover;
  background-position: 50%;
  background-repeat: no-repeat;
  margin-right: 11px;
}
.achievements-list-extended__badge {
  position: absolute;
  top: 6px;
  left: 6px;
  padding: 0 2px;
  color: #0071b9;
  font-size: 14px;
  font-weight: 500;
  background: #fff;
  border-radius: 50%;
  min-width: 24px;
  text-align: center;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.22);
}
.achievements-list-extended__title {
  font-size: 16px;
  font-weight: 500;
  color: #0071b9;
}
.achievements-list-extended__title .icon-info {
  vertical-align: top;
  margin-top: -2px;
}
.achievements-list-extended__text {
  padding-left: 3px;
}
.achievements-list-extended__hint {
  position: absolute;
}
.bubble {
  position: absolute;
  z-index: 1;
  padding: 24px;
  width: 286px;
  background-color: #fff;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.15);
  visibility: hidden;
  opacity: 0;
  font-size: 14px;
  text-align: center;
  line-height: 24px;
  font-weight: normal;
}
.bubble:before,
.bubble:after {
  transform: rotate(45deg);
  transform-origin: right;
  position: absolute;
  border-style: solid;
  right: 100%;
  top: 100%;
  content: '';
  z-index: 1;
}
.bubble:before {
  border-width: 17px 6px 0;
  border-color: rgba(0,0,0,0.05) transparent transparent;
  margin: -1px -4px 0;
}
.bubble:after {
  border-width: 16px 5px 0;
  border-color: #fff transparent transparent;
  margin: -3px -3px 0;
}
[data-bubble-parent]:hover .bubble {
  opacity: 1;
  visibility: visible;
}
.change-info-form {
  margin: 0 auto;
  max-width: 310px;
}
.study-preview {
  display: flex;
  flex-wrap: wrap;
  font-size: 14px;
  line-height: 24px;
  font-weight: normal;
  justify-content: space-between;
  align-items: center;
}
.study-preview__text {
  max-width: calc(100% - 60px);
  color: #393939;
}
.study-preview__percentage {
  font-size: 16px;
  font-weight: 500;
  width: 60px;
  text-align: right;
}
.study-preview__progress {
  position: relative;
  width: 100%;
  margin: 10px 0 0;
  background: #e7e7e7;
  height: 4px;
  border-radius: 3px;
}
.study-preview__progress-inner {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  background-image: linear-gradient(271deg, #2ea694, #2ba08f 6%, #033943);
  border-radius: 3px;
}
.study-preview-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: -15px;
}
.study-preview-list__item {
  width: 422px;
  margin: 0 0 15px;
}
.study-preview-listafter {
  display: block;
  width: 422px;
  content: '';
}
.study-preview-list+.btn-wrap {
  margin-top: 30px;
}
.study-preview-wrap {
  margin: 70px 0;
}
.study-preview-heading {
  text-align: center;
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 20px;
}
/*===== end Profile Page styles ====*/
/*===== Rating page styles ====*/
.rating-page {
  font-size: 14px;
  font-weight: 400;
}
.rating-page-header {
  margin-bottom: 60px;
  max-width: 680px;
}
.rating-page-header * + * {
  margin-top: 30px;
}
.rating-filter {
  display: flex;
  margin-bottom: 20px;
}
.rating-filter__sort {
  display: flex;
  align-items: center;
  border-left: 1px solid #e7e7e7;
  margin-left: 20px;
  padding-left: 10px;
}
.rating-filter__btn {
  position: relative;
  margin: 0 5px;
  width: 20px;
  height: 20px;
}
.rating-filter__btn.is-top:after {
  top: 13px;
}
.rating-filter__btn.is-bottom:after {
  top: 9px;
}
.rating-filter__btn:after {
  right: 5px;
}
.rating-list {
  margin-bottom: 60px;
}
.rating-list__result {
  flex-shrink: 0;
  margin-left: 15px;
}
.rating-list__item,
.rating-list__user {
  display: flex;
  align-items: center;
}
.rating-list__item {
  position: relative;
  justify-content: space-between;
  padding: 12px 40px 12px 80px;
  counter-increment: item;
  border-bottom: 1px solid #e7e7e7;
}
.rating-list__item--user {
  background-color: #0068a1;
  color: #fff;
}
.rating-list__item--user .rating-list__score {
  font-size: 16px;
}
.rating-list__item--user .rating-list__name {
  font-weight: 500;
}
.rating-list__item:first-child {
  border-top: 1px solid #e7e7e7;
}
.rating-list__count {
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 100%;
  font-weight: 700;
}
.rating-list__pic {
  margin-right: 22px;
  width: 41px;
  min-width: 41px;
  height: 41px;
}
.rating-list__score {
  font-weight: 500;
}
/*===== end Rating Page styles ====*/
.main-test-page {
  font-size: 14px;
  font-weight: 400;
}
.main-test-page .article-header {
  margin-bottom: 30px;
}
.main-test-page p + p {
  margin-top: 30px;
}
.test-page {
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 395px);
  padding: 50px 0;
}
@media only screen and (max-width: 1023px) {
  .test-page {
    min-height: calc(100vh - 341px);
    padding: 48px 0;
  }
}
.test-page.is-question {
  padding-top: 65px;
}
.test-page.is-question:before {
  content: '';
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  background-color: #f9f9f9;
  width: calc(((100vw - 950px) / 2) + (950px - 710px));
  z-index: 0;
  pointer-events: none;
}
.test-page.is-question .test-page__wrapper {
  flex-direction: row;
  align-items: stretch;
  justify-content: space-between;
}
.test-page__wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
}
.test-heading {
  max-width: 470px;
  font-size: 18px;
  line-height: 31px;
  font-weight: 400;
  color: #0071b9;
  text-align: center;
  margin-bottom: 15px;
}
.test-description {
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  margin-top: 16px;
  max-width: 800px;
}
.test-list {
  display: flex;
  margin-top: 48px;
  margin-bottom: 80px;
  width: 100%;
  max-width: 800px;
}
.test-list-item {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 200px;
}
.test-list-item:not(:last-child):after {
  content: '';
  position: absolute;
  top: 18px;
  right: 0;
  display: block;
  width: 1px;
  height: 19px;
  background-color: #393939;
  opacity: 0.2;
}
.test-list-item.is-good .test-list-item__value {
  color: #2da492;
}
.test-list-item.is-bad .test-list-item__value {
  color: #f00;
}
.test-list-item__value {
  display: flex;
  align-items: center;
  color: #0071b9;
  padding-top: 16px;
  padding-bottom: 16px;
}
.test-list-item__value .is-value {
  font-size: 28px;
  line-height: 20px;
}
.test-list-item__value .is-unit {
  line-height: 20px;
  margin-left: 7px;
}
.test-list-item__description {
  max-width: 170px;
  text-align: center;
  font-size: 14px;
  line-height: 19px;
}
.test-info-buttons > * + * {
  margin-left: 48px;
}
.test-sidebar {
  display: flex;
  flex-direction: column;
  width: 240px;
  padding-right: 40px;
}
.test-sidebar-heading {
  font-size: 14px;
  line-height: 22px;
  margin-bottom: 44px;
}
.test-sidebar-list__item + .test-sidebar-list__item {
  margin-top: 18px;
}
.test-sidebar-list__label {
  font-size: 11px;
  line-height: 17px;
  margin-bottom: 5px;
}
.test-sidebar-list__value {
  font-size: 28px;
  line-height: 54px;
  color: #0071b9;
}
.test-sidebar-list__value span {
  color: #393939;
  font-size: 11px;
  line-height: 17px;
}
.test-main {
  display: flex;
  flex-direction: column;
  width: 69%;
}
.test-main-heading {
  max-width: 560px;
  font-size: 18px;
  line-height: 31px;
  color: #0071b9;
  margin-bottom: 37px;
}
.test-main-results {
  position: relative;
  display: inline-flex;
  margin-top: 40px;
}
.test-main-results__list,
.test-main-results__items {
  display: flex;
  flex-wrap: wrap;
}
.test-main-results__item {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 3px 5px;
  width: 20px;
  height: 20px;
  border-radius: 100%;
  border: 1px solid #d7d7d7;
  font-size: 10px;
  color: #d7d7d7;
}
.test-main-results__item.is-false {
  color: #ed1020;
  border-color: #ed1020;
}
.test-main-results__item.is-true {
  color: #00cb89;
  border-color: #00cb89;
}
.test-main-results__item.is-current {
  border-color: #0071b9;
  background: #0071b9;
  color: #fff;
}
.test-main__btn {
  position: relative;
  width: 20px;
  height: 20px;
}
.test-main__btn:after {
  content: "";
  position: absolute;
  top: 50%;
  display: block;
  width: 10px;
  height: 10px;
  border: solid #0071b9;
  border-width: 0 2px 2px 0;
}
.test-main__btn.is-prev:after {
  transform: translate(50%, -50%) rotate(135deg);
}
.test-main__btn.is-next:after {
  transform: translate(50%, -50%) rotate(-45deg);
}
.test-main-list__item,
.checkbox__item {
  position: relative;
  display: flex;
}
.test-main-list__item + .test-main-list__item,
.checkbox__item + .checkbox__item {
  margin-top: 17px;
}
.test-main-list__item input,
.checkbox__item input {
  display: none;
}
.test-main-list__label,
.checkbox__label {
  position: relative;
  font-size: 14px;
  line-height: 22px;
  padding-left: 33px;
  font-weight: 400;
  cursor: pointer;
}
.test-main-list__label:before,
.checkbox__label:before {
  content: '';
  position: absolute;
  left: 0;
  top: 4px;
  display: block;
  width: 12px;
  height: 12px;
  background-color: #fff;
  box-shadow: 0 1px 3px rgba(0,0,0,0.3);
}
.test-main-list__label:after,
.checkbox__label:after {
  content: '';
  position: absolute;
  display: none;
}
.test-main-list__item input:checked + .test-main-list__label:after,
.checkbox__item input:checked + .checkbox__label:after {
  display: block;
}
.test-main-list__item.is-radio .test-main-list__label:before,
.checkbox__item.is-radio .checkbox__label:before {
  border-radius: 50%;
}
.test-main-list__item.is-radio .test-main-list__label:after,
.checkbox__item.is-radio .checkbox__label:after {
  left: 3px;
  top: 7px;
  width: 6px;
  height: 6px;
  background-color: #2da492;
  border-radius: 50%;
}
.test-main-list__item.is-checkbox .test-main-list__label:after,
.checkbox__item.is-checkbox .checkbox__label:after {
  left: 2px;
  top: 6px;
  width: 9px;
  height: 5px;
  border-left: 2px solid #2da492;
  border-bottom: 2px solid #2da492;
  transform: rotate(-45deg);
}
.test-main-buttons {
  margin-top: 70px;
}
.test-main-buttons > * + * {
  margin-left: 45px;
}
.page404 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 395px);
}
.old-browser-page {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -120px;
  min-height: 100vh;
  width: 100%;
}
.old-browser-container,
.page404-container {
  padding: 0 10px;
  width: 100%;
  font-size: 18px;
  line-height: 31px;
  font-weight: 300;
  text-align: center;
  color: #0071b9;
}
.old-browser-page-wrap {
  display: flex;
}
.old-browser-container {
  max-width: 750px;
}
.page404-container {
  max-width: 610px;
  text-align: center;
}
.page404-heading {
  margin-bottom: 30px;
  color: #0071b9;
  font-weight: 300;
  font-size: 96px;
  line-height: 116px;
}
.old-browser__title {
  margin-bottom: 65px;
}
.browser-list {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
  max-width: 500px;
  width: 100%;
}
.browser-list__link {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #bdbdbd;
  transition: color 0.33s ease-in-out;
}
.browser-list__link svg {
  margin-bottom: 22px;
  height: 45px;
}
.browser-list__link svg path {
  transition: all 0.33s ease-in-out;
}
.browser-list__link:hover {
  color: #0071b9;
  transition: color 0.33s ease-in-out;
}
.browser-list__link:hover svg path {
  fill: #0071b9;
  transition: all 0.33s ease-in-out;
}
.pdf-container {
  position: relative;
  padding-bottom: 57.25%;
  height: 0;
  overflow: hidden;
}
.pdf-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
/*===== 768 styles ====*/
@media only screen and (min-width: 1023px) {
  section .wrapper > * {
    opacity: 0;
    transform: translateY(50px);
  }
  section .wrapper > *.is-visible {
    opacity: 1;
    transform: translate(0);
    transition: opacity 0.7s cubic-bezier(0.25, 0.1, 0, 0.85), transform 0.7s cubic-bezier(0.25, 0.1, 0, 0.85);
  }
  .achievements-list-extended {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    padding-left: 44px;
  }
  .achievements-list-extended__item:nth-child(odd) {
    width: 280px;
    margin-right: 35px;
  }
}
@media only screen and (max-width: 1023px) {
  body {
    padding-top: 66px;
  }
  .wrapper {
    padding: 0 69px;
  }
  .wrapper--secondary {
    padding-right: 30px;
  }
  .wrapper--inner {
    max-width: 850px;
  }
  .studies,
  .questions,
  .filter-list {
    align-self: center;
    max-width: 640px;
  }
  .filter-list__item {
    width: 31%;
  }
  .header {
    height: 70px;
  }
  .header .main-menu {
    border-bottom: none;
  }
  .header .main-menu__link {
    height: 70px;
  }
  .header--scroll .profile {
    transform: none;
  }
  .header-wrapper {
    padding: 0 30px;
  }
  .header--secondary {
    background-color: $blues;
  }
  .profile,
  .logo-wrap {
    transform: translate(0, 0);
    transition: transform 0.33s ease;
  }
  .logo-link {
    width: 100px;
  }
  .logo-static {
    display: none;
  }
  .logo-scroll {
    display: block;
    visibility: visible;
    transform: scale(1);
  }
  .profile {
    min-width: auto;
  }
  .profile__btn {
    display: none;
  }
  .profile-item {
    margin-top: 16px;
    position: static;
    width: auto;
    box-shadow: none;
    background: transparent;
  }
  .profile-user {
    padding: 0;
    height: auto;
  }
  .profile-user__pic {
    margin: 0;
  }
  .profile-user__link {
    padding: 0;
  }
  .profile-user__link--notification:before {
    content: '';
    position: absolute;
    top: 0;
    right: -6px;
    width: 13px;
    height: 13px;
    border: 4px solid #f00;
    border-radius: 50%;
    background-color: #fff;
  }
  .profile-user__link span {
    display: none;
  }
  .profile-user__link:after {
    display: none;
  }
  .achievements-list-extended {
    padding-left: 82px;
    margin-bottom: -10px;
  }
  .achievements-list-extended__item {
    margin-bottom: 10px;
  }
  .achievements-list-extended__item:hover .bubble {
    opacity: 0;
    visibility: hidden;
  }
  .achievements-list-extended__image {
    margin-right: 23px;
    width: 55px;
    height: 55px;
  }
  .achievements-list-extended__title .icon-info .bubble {
    left: 0;
    bottom: 100%;
    max-width: 100%;
  }
  .achievements-list-extended__title .icon-info .bubble:after,
  .achievements-list-extended__title .icon-info .bubble:before {
    transform: none;
    right: auto;
    left: 0;
  }
  .achievements-list-extended__title .icon-info .bubble:after {
    margin: 0;
  }
  .achievements-list-extended__title .icon-info .bubble:before {
    margin: 0 0 0 -1px;
  }
  .achievements-list-extended .icon-info:hover .bubble {
    opacity: 1;
    visibility: visible;
  }
  .study-preview-list__item {
    width: 288px;
  }
  .study-preview {
    line-height: 18px;
  }
  .page {
    padding: 48px 0 80px;
  }
  .article-page {
    padding: 70px 0 90px;
  }
  .intro-section {
    margin-top: -66px;
    padding: 66px 0;
  }
  .main-section {
    padding: 75px 0 60px;
  }
  .main-section .studies-item:nth-child(n+5),
  .main-section .questions-item:nth-child(n+5) {
    display: none;
  }
  .md-visible {
    display: flex;
  }
  .section-header {
    margin-bottom: 55px;
  }
  .top-news-content {
    margin-right: -40px;
    padding-left: 10px;
  }
  .studies-page .article-heading,
  .contests-page .article-heading {
    text-align: center;
  }
  .article-slider {
    padding-bottom: 50px;
  }
  .article-slider__counter,
  .article-slider .slick-arrow {
    bottom: 0;
    top: auto;
    right: auto;
    transform: translate(0, 0);
  }
  .article-slider__counter {
    left: 0;
  }
  .article-slider .slick-prev {
    left: 100px;
  }
  .article-slider .slick-next {
    left: 130px;
  }
  .notification-atestation {
    flex-direction: column;
  }
  .notification-atestation-text {
    margin-right: 0;
  }
  .notification-atestation-btn {
    align-self: flex-start;
    margin-top: 15px;
  }
  .authorization {
    margin-top: -66px;
  }
  .personal-left-block {
    width: auto;
    padding-right: 50px;
  }
  .achievements-list__item {
    margin: 20px;
    width: 55px;
    height: 55px;
  }
  .test-page.is-question:before {
    max-width: 270px;
    width: 30%;
  }
  .test-main {
    width: 75%;
  }
  .page404 {
    height: calc(100vh - 341px);
  }
}
@media only screen and (max-width: 767px) {
  body {
    padding-top: 53px;
  }
  .wrapper {
    padding: 0 15px;
  }
  .wrapper--secondary,
  .wrapper--inner {
    padding-right: 15px;
  }
  .header-wrapper {
    align-items: center;
    padding: 0 15px;
    height: 100%;
  }
  .header--secondary .mobile-nav:not(.mobile-nav--open) span,
  .header--scroll .mobile-nav:not(.mobile-nav--open) span {
    background-color: #fff;
    transition: all 0.33s ease;
  }
  .profile {
    margin-left: auto;
    margin-right: 30px;
  }
  .achievements-list-extended {
    padding-left: 30px;
  }
  .study-preview-list__item {
    width: 100%;
  }
  .mobile-nav {
    display: block;
  }
  .mobile-nav--open {
    z-index: 1000;
  }
  .mobile-nav--open span {
    background-color: #fff;
  }
  .main-nav {
    position: fixed;
    left: -110%;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
    background-color: #0071b9;
    transition: transform 0.33s ease;
  }
  .main-nav--open {
    display: flex;
    transform: translateX(110%);
    transition: transform 0.33s ease;
    z-index: 999;
  }
  .main-nav--open .main-menu {
    opacity: 1;
    transition: opacity 0.33s ease-in-out;
    transition-delay: 0.33s;
  }
  .main-menu {
    flex-direction: column;
    margin: 0;
    width: 100%;
    border-bottom: none;
    opacity: 0;
  }
  .main-menu__item + .main-menu__item {
    margin-left: 0;
  }
  .main-menu__link {
    font-size: 18px;
    line-height: 27px;
    text-transform: uppercase;
  }
  .main-menu .main-menu__link {
    color: #fff;
  }
  .studies-item,
  .questions-item {
    width: 48%;
  }
  .link--back {
    margin-left: 0;
  }
  .page {
    padding: 40px 0 60px;
  }
  .contests-page,
  .news-page {
    padding-top: 0;
  }
  .top-news {
    margin: 0 -15px 40px;
    min-height: 400px;
    color: #fff;
  }
  .top-news-content {
    width: 100%;
    padding: 30px 15px 50px;
  }
  .top-news .tag {
    border-color: #fff;
  }
  .top-news .tag:before {
    background-color: #fff;
  }
  .top-news .news-heading {
    color: #fff;
  }
  .top-news-pic {
    position: absolute;
    top: 0;
    left: 0;
    max-width: 100%;
    width: 100%;
    height: 100%;
    z-index: -1;
  }
  .test-main {
    width: 90%;
    margin-left: 30px;
  }
  .old-browser__title span {
    display: block;
    margin: 0 auto;
  }
}
@media only screen and (max-width: 567px) {
  .header {
    height: 53px;
  }
  .main-heading {
    font-size: 36px;
    line-height: 36px;
  }
  .section-heading {
    font-size: 30px;
  }
  .article-heading {
    font-size: 24px;
    line-height: 30px;
  }
  .intro-section {
    font-size: 13px;
  }
  .section-header {
    margin-bottom: 40px;
  }
  .main-section {
    padding: 60px 0;
  }
  .main-section.questions-section {
    position: relative;
    padding-bottom: 100px;
  }
  .main-section.questions-section .questions-section__link {
    position: absolute;
    bottom: 40px;
    left: 50%;
    transform: translateX(-50%);
    min-width: 170px;
  }
  .md-visible {
    margin-top: 50px;
  }
  .result-popup-container {
    padding: 30px 15px;
  }
  .top-news {
    min-height: 320px;
  }
  .studies,
  .questions {
    max-width: 310px;
  }
  .studies-item,
  .questions-item {
    width: 100%;
  }
  .main-section .studies-item:nth-child(n+4),
  .main-section .questions-item:nth-child(n+4) {
    display: none;
  }
  .btn-wrap + .article {
    margin-top: 45px;
  }
  .article + .btn-wrap {
    margin-top: 45px;
  }
  .article-page .btn-wrap:last-of-type {
    margin-top: 40px;
  }
  .article-header {
    margin-bottom: 30px;
  }
  .article-header >*+* {
    margin-top: 20px;
  }
  .article .article-heading {
    font-size: 18px;
    line-height: 27px;
  }
  .article__title {
    font-size: 15px;
    line-height: 22px;
  }
  .article-content >* + * {
    margin-top: 30px;
  }
  .article-user {
    padding: 0 0 25px;
    border: none;
    box-shadow: none;
    border-radius: 0;
    border-bottom: 1px solid #979797;
  }
  .article-user__content * + * {
    margin-top: 19px;
  }
  .article-user__pic {
    margin-left: 0;
  }
  .article-page-section >* + * {
    margin-top: 35px;
  }
  .comments-form {
    flex-direction: column;
  }
  .comments-form__pic {
    margin-bottom: 15px;
    width: 41px;
    height: 41px;
  }
  .comments-form__field {
    margin-bottom: 10px;
  }
  .comments-form__btns {
    flex-direction: row-reverse;
  }
  .comments-form__btns button {
    margin-left: 0;
    margin-right: 30px;
  }
  .comments-form:not(.comments-form--unboxed) {
    padding: 0 0 25px;
    border: none;
    box-shadow: none;
    border-radius: 0;
    border-bottom: 1px solid #979797;
  }
  .questions-header {
    flex-direction: column;
  }
  .questions-header >* + * {
    margin-top: 25px;
  }
  .questions-header-text {
    margin-right: 0;
    max-width: 100%;
  }
  .questions-header-text * + * {
    margin-top: 19px;
  }
  .questions-title {
    font-size: 14px;
  }
  .questions-box {
    margin-top: 55px;
  }
  .questions-box-header {
    display: flex;
  }
  .questions-box__btn {
    font-size: 14px;
    text-align: left;
  }
  .questions-box__btn + .questions-box__btn {
    padding-left: 30px;
    border-left: 1px solid #979797;
  }
  .questions-list__item {
    margin: 0 -15px;
  }
  .questions-list__item .is-top,
  .questions-list__item .is-bottom {
    right: 20px;
    bottom: 5px;
    top: auto;
  }
  .questions-list__question,
  .questions-list__answer {
    padding: 30px 15px;
  }
  .questions-list__content {
    padding-left: 0;
  }
  .notification,
  .personal {
    display: none;
  }
  .notification.is-open,
  .personal.is-open {
    display: block;
  }
  .personal-page-tabs {
    display: block;
  }
  .personal-page__tab {
    margin-right: 28px;
    color: #0071b9;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.72;
    opacity: 0.5;
    cursor: pointer;
  }
  .personal-page__tab.is-active {
    opacity: 1;
  }
  .personal-page__tab+.personal-page__tab {
    padding-left: 28px;
    border-left: 1px solid #979797;
  }
  .notification-block {
    margin-top: 0;
    padding: 30px 45px 30px 30px;
    border-bottom: 1px solid #979797;
    color: #393939;
    background-color: transparent;
  }
  .notification-block:before {
    width: 4px;
    left: 0;
    background-color: transparent;
    background: center no-repeat url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI0IiBoZWlnaHQ9IjE2IiB2aWV3Qm94PSIwIDAgNCAxNiI+CiAgICA8ZyBmaWxsPSIjMDA3MUI5IiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPgogICAgICAgIDxwYXRoIGQ9Ik0wIDEwaDRWMEgwek0wIDE2aDR2LTRIMHoiLz4KICAgIDwvZz4KPC9zdmc+Cg==");
  }
  .notification-block__close {
    right: 0;
  }
  .notification-block__close:before,
  .notification-block__close:after {
    background-color: #0071b9;
  }
  .notification-atestation {
    margin-top: 30px;
    padding: 0;
    border: none;
  }
  .personal-information,
  .personal-block,
  .personal-info {
    flex-direction: column;
  }
  .personal-info__row {
    margin-bottom: 30px;
    width: 100%;
    padding: 0;
  }
  .personal-info__btns {
    flex-direction: column-reverse;
    align-items: flex-start;
    padding: 0 0 25px;
    border-top: none;
    border-bottom: solid 1px #e7e7e7;
  }
  .personal-info__btns .btn {
    margin-bottom: 35px;
  }
  .personal-form-wrap {
    align-items: flex-start;
    margin-bottom: 30px;
  }
  .personal-img-form {
    display: flex;
  }
  .personal-photo {
    margin-right: 30px;
    margin-bottom: 0;
    width: 80px;
    height: 80px;
  }
  .personal-left-block {
    padding-right: 0;
  }
  .personal-rating {
    padding-bottom: 40px;
    border-bottom: solid 1px #e7e7e7;
  }
  .personal-rating >* + * {
    margin-top: 20px;
  }
  .personal-achievements {
    padding-top: 30px;
    border-left: none;
  }
  .achievements-list-extended {
    padding-left: 0;
  }
  .personal-page__subheading + .achievements-list-extended {
    margin-top: 20px;
  }
  .achievements-list {
    justify-content: center;
    margin-top: 0;
  }
  .achievements-list__item {
    width: 75px;
    height: 75px;
  }
  .personal-information + .personal-block {
    margin-top: 30px;
  }
  .test-heading {
    text-align: left;
    line-height: 27px;
  }
  .test-list {
    flex-direction: column;
    margin: 20px 0 50px;
  }
  .test-list-item {
    flex-direction: row;
    width: 100%;
  }
  .test-list-item:not(:last-child):after {
    display: none;
  }
  .test-list-item__description {
    max-width: 100%;
    text-align: left;
    font-size: 15px;
  }
  .test-list-item__value {
    margin-right: 15px;
    min-width: 70px;
  }
  .test-page.is-question {
    padding-top: 0;
  }
  .test-page.is-question:before {
    top: 53px;
    max-width: 100%;
    width: 100%;
    height: 82px;
    background-color: #e8f4fc;
  }
  .test-page.is-question .test-page__wrapper {
    flex-direction: column;
    justify-content: flex-start;
  }
  .test-sidebar {
    width: 100%;
    padding: 5px 0;
  }
  .test-sidebar-heading {
    display: none;
  }
  .test-sidebar-list {
    display: flex;
  }
  .test-sidebar-list-item+.test-sidebar-list-item {
    margin-left: 30px;
  }
  .test-sidebar-list__value {
    font-size: 18px;
    font-weight: 400;
  }
  .test-main {
    margin-left: 0;
    width: 100%;
  }
  .test-main-heading {
    margin: 20px 0;
    font-size: 14px;
    line-height: 22px;
  }
  .test-main-buttons {
    display: flex;
    margin-top: 40px;
  }
  .rating-page-header {
    margin-bottom: 40px;
  }
  .rating-page-header * + * {
    margin-top: 12px;
  }
  .rating-list {
    margin: 0 -15px 50px;
  }
  .rating-list__item {
    padding-left: 46px;
    padding-right: 28px;
  }
  .rating-list__count {
    width: 46px;
  }
  .rating-list__pic {
    margin-right: 15px;
  }
  .filter-list {
    display: none;
  }
  .studies-select {
    display: block;
  }
  .footer-info {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .footer-info__wrap.is-left,
  .footer-info__wrap.is-right {
    text-align: center;
  }
  .footer-info__wrap + .footer-info__wrap {
    margin-top: 21px;
  }
  .page404 {
    height: auto;
    min-height: calc(100vh - 436px);
  }
  .page404-container {
    font-size: 14px;
    line-height: 22px;
  }
  .page404-heading {
    margin-bottom: 22px;
    font-size: 70px;
    line-height: 84px;
  }
}
@media only screen and (max-width: 639px) {
  .question-form .comments-form__btns {
    margin-top: 20px;
    flex-direction: row-reverse;
  }
  .question-form .comments-form__btns button {
    margin-left: 0;
    margin-right: 30px;
  }
}
/*===== end 768 styles ====*/
/*===== News Page styles ====*/
@media only screen and (max-width: 767px) {
  .top-news {
    display: block;
  }
  .top-news-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 20px 16px 0;
  }
  .top-news-pic {
    position: relative;
    height: 300px;
    min-width: 100%;
  }
  .top-news-pic img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: block;
  }
  .top-news .news-heading {
    color: #393939;
    margin-bottom: 0;
    order: 1;
  }
  .top-news .tag {
    color: #393939;
    padding: 0;
    border: none;
    display: block;
    margin: 13px 0 18px;
    order: 2;
  }
  .top-news .tag:before {
    display: none;
  }
  .top-news .btn {
    order: 3;
  }
  .studies-item__tag {
    color: #393939;
    padding: 0;
    border: none;
    display: block;
    margin: 13px 0 18px 30px;
  }
  .studies-item__tag:before {
    display: none;
  }
}
/*===== end News Page styles ====*/
/*===== header styles ====*/
@media only screen and (max-width: 767px) {
  .profile-item {
    margin-top: 0;
  }
}
/*===== end header styles ====*/

/*!===== Studies Page styles ====*/
.page
  padding 80px 0 120px    
.studies-page   
  &-header
    margin-bottom 48px
    * + *
      margin-top 18px
.studies-select
  display none
  margin 0 auto 30px
  width 300px
  max-width 100%
.filter-list
  display flex
  flex-wrap wrap
  margin 0 -5px 40px
  &__item
    position relative
    display flex
    align-items center
    justify-content center
    margin 5px
    padding 20px 15px
    width 230px
    height 80px
    border-radius 12px
    font-size 14px
    background-color #ffffff
    border 1px solid transparent
    box-shadow 0 2px 6px 0 rgba(0, 0, 0, 0.15)
    cursor pointer
    transition border-color .33s ease

    &:hover
      border 1px solid $green
      transition border-color .33s ease

    &.is--active
      color #fff
      background-image: linear-gradient(306deg, #7dc7c6, #0b4f4e);
    &.is--season:after
      content ''
      position absolute
      top 10px
      right 10px
      width 18px
      height 18px
      border-radius 50%
      background-image embedurl('../img/admin-ico.svg')

/*!===== end Studies Page styles ====*/
/*!===== Questions page styles ====*/

.page--questions
  font-size 14px
.questions-header
  display flex
  justify-content space-between
  & + *
    margin-top 30px

  &-btn
    flex-shrink 0

  &-text
    margin-right 15px
    max-width 520px
    * + *
      margin-top 30px
  & + .comments-form
    display none

.questions-box
  margin-top 68px
  &-header
    margin-bottom 30px
  &__btn
    margin-right 30px
    font-size 18px
    font-weight 500
    line-height 1.72
    color #6d6d6d
    cursor pointer
    &.is-active
      color $blue
    &:hover
      color $blueHover

.questions-list
  &__item
    border-bottom 2px solid #e7e7e7
    &:first-child
      border-top 2px solid #e7e7e7
    &.is-open
      background-color #f9f9f9
    &.is-open .questions-list__toggle-button:after
      transform rotate(225deg)
    &.is-open .questions-list__answers
      display block

  &__toggle-button
    position absolute
    top 0
    right 0
    bottom 0
    display block
    width 60px
    height 100%
    cursor pointer
    transition background-color .2s
    &:hover
      background-color #f9f9f9
    &:after
      position absolute
      top 0
      right 0
      bottom 0
      left 0
      margin auto
      display block
      width 10px
      height 10px
      border solid $blue
      border-width 0 2px 2px 0
      transform rotate(45deg)

  &__header
  &__info
    display flex
    align-items center
    justify-content space-between
  &__pic
    position relative
    margin 0 20px 0 10px
    width 41px
    height 41px
  &__date
    font-size 11px
  &__name
    font-size 13px
    font-weight 700
  &__content
    margin-top 20px
    padding-left 71px
    font-size 14px
    * + *
      margin-top 30px
    p
      width 100%
  &__img
    max-width 50%
  &__question
    position relative
    padding 30px 80px 40px 0
    background-color #fff
  &__answers
    display none
    .comments-form
      padding 40px 80px 30px 110px
  &__answer
    position relative
    padding 30px 80px 20px 40px
    background-color #f9f9f9
    cursor pointer
  & + .btn-wrap
    margin-top 60px

/*!===== end Questions Page styles ====*/

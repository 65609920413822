// ===========================================
// Test info
// ===========================================

.page404
  display flex
  flex-direction column
  justify-content center
  align-items center
  height calc(100vh - 395px)
.old-browser-page
  display flex
  align-items center
  justify-content center
  margin-top -120px
  min-height 100vh
  width 100%
.old-browser-container
.page404-container
  // margin 0 auto
  padding 0 10px
  width 100%
  font-size 18px
  line-height 31px
  font-weight 300
  text-align center
  color $blue
.old-browser-page-wrap
  display flex
.old-browser-container
  max-width 750px
.page404-container
  max-width 610px
  text-align center
  
.page404-heading
  margin-bottom 30px
  color $blue
  font-weight 300
  font-size 96px
  line-height 116px
.old-browser__title
  margin-bottom 65px
.browser-list
  display flex
  align-items center
  justify-content space-between
  margin 0 auto
  max-width 500px
  width 100%
  &__link
    display flex
    flex-direction column
    align-items center
    color #BDBDBD
    transition color .33s ease-in-out

    svg 
      margin-bottom 22px
      height 45px
      path
        transition all .33s ease-in-out

    &:hover 
      color #0071B9
      transition color .33s ease-in-out
      svg path  
        fill #0071B9
        transition all .33s ease-in-out

.pdf-container
  position relative
  padding-bottom 57.25%
  height 0
  overflow hidden

  iframe
    position absolute
    top 0
    left 0
    width 100%
    height 100%
/*!===== Footer styles ====*/
.footer
  position relative
  padding 30px 0
  min-height 220px
  color #fff
  background-color #0071b9

  div + div:not(.footer-info__wrap) 
    margin-top 30px

  &-logo 
    &__link
      margin 0 auto
      display block
      height 53px
      width 39px

  &-copyright
    text-align center
    &__text
      font-size 11px 
      line-height 17px
      font-weight 400

  &-socials
    display flex
    align-items center
    justify-content center
    
    &__link
      margin 0 10px
      padding 5px
      height 28px

      svg path
        transition fill .33s ease

      &:hover svg path
        fill #61beff
        transition fill .33s ease

  &-info
    display flex
    justify-content space-between
    &__wrap
      width 33.333333%
      text-align center
      &.is-left
        text-align left
      &.is-right
        text-align right
    &__link
      font-size 11px
      line-height 17px
      color #fff
      text-decoration underline
      font-weight 400
/*!===== end Footer styles ====*/
/*!===== News Page styles ====*/
+below(768px)

  .top-news
    display: block

    &-content
      display: flex
      flex-direction: column
      align-items: flex-start
      padding:20px 16px 0;

    &-pic
      position: relative
      height: 300px
      min-width: 100%

      img
        position: absolute
        left: 0
        top: 0
        width: 100%
        height: 100%
        display: block

    .news-heading
      color: $black;
      margin-bottom: 0
      order 1

    .tag
      color: $black;
      padding: 0;
      border: none
      display: block
      margin:13px 0 18px;
      order 2

      &:before
        display: none

    .btn
      order 3

  .studies-item

    &__tag
      color: $black;
      padding: 0;
      border: none
      display: block
      margin:13px 0 18px 30px;

      &:before
        display: none

/*!===== end News Page styles ====*/

/*!===== header styles ====*/
+below(768px)

  .profile
    &-item
      margin-top: 0
/*!===== end header styles ====*/

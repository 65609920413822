/*!===== Profile page styles ====*/
.personal-page  
  &__heading
    margin-bottom 30px
  &__subheading
    font-size 18px
    line-height 30px
    font-weight 500
  &-tabs
    display none
  &__exit
      margin-top 35px
  
.notification 
  font-size 14px 
  font-weight 400
  > * + * 
    margin-top 40px
  &-block
    position relative
    padding 23px 66px 23px 91px
    color #fff
    background-color #0068a1
    a
      color #fff
    &--close
      display none
      & + *
        margin-top 0
    &:before
      content ''
      position absolute
      left 0
      top 0
      width 66px
      height 100%
      background center no-repeat embedurl('../img/attention-ico.svg')
      background-color #004179
    &__close
      position absolute
      right 30px
      top 50%
      transform translateY(-50%)
      width 22px
      height 22px
      &:before
      &:after
        background-color #fff
      
  &-atestation
    display flex
    align-items center
    justify-content space-between
    padding 21px 40px
    border solid 1px #e7e7e7
    &-text
      margin-right 15px
      max-width 570px
      * + *
        margin-top 15px
    &-btn
      flex-shrink 0

  & + .personal
    margin-top 65px
    
.personal
  &-photo
    margin-bottom 18px
    width 151px
    height 151px
    background #e7e7e7 center no-repeat  
    background-image embedurl('../img/user-pic-bg.svg')
    background-size 50px
    
  .link input
    display none

  &-information
  &-block
    display flex

  &-information + .personal-block
    margin-top 100px
  &-left-block
    display flex
    align-items center
    justify-content flex-start
    flex-direction column
    flex-shrink 0
    width 280px
    padding-right 20px
  &-right-block
    display flex
    flex-wrap wrap
    flex-grow 2

  &-img-form
    text-align center
  &-info
    &__descr
      margin-bottom 13px
      font-size 11px
      line-height 24px
      color #868686
    &__row
      margin-bottom 35px
      width 50%
      padding-right 15px
    &__btns
      display flex
      align-items center
      justify-content space-between
      flex-grow 2
      padding 25px 40px 0 0 
      border-top solid 1px #e7e7e7

  &-rating
    > * + *
      margin-top 40px
    &__score
      font-size 48px
      font-weight 300
      line-height 1.13
      text-align center
      color $blue

    &__descr
      display block
      text-align center
      font-size 14px
      font-weight 400

  &-achievements
    flex-direction column
    align-items center
    border-left 1px solid #e7e7e7
    >* + *
      margin-top 30px

.achievements-list
  display flex
  flex-wrap wrap
  max-width 500px

  &__item
    margin 20px 25px
    width 75px
    height 75px

.icon-info
  display inline-block
  width 12px
  height 12px
  background embedurl('../img/info-icon.svg')

.achievements-list-extended
  width 100%
  margin-bottom -20px

  &__item
    position relative
    display flex
    align-items center
    font-size 14px
    line-height 24px
    font-weight normal
    margin-bottom 20px

    .bubble
      left 53px
      bottom 53px

  &__image
    width 78px
    height 78px
    border 2px solid white
    box-shadow 0 2px 4px 0 rgba(0, 0, 0, 0.22)
    border-radius 50%
    overflow hidden
    background-size cover
    background-position 50%
    background-repeat no-repeat
    margin-right 11px

  &__badge
    position absolute
    top 6px
    left 6px
    padding 0 2px
    color $blue
    font-size 14px
    font-weight 500
    background white
    border-radius 50%
    min-width 24px
    text-align center
    box-shadow 0 2px 4px 0 rgba(0, 0, 0, 0.22)

  &__title
    font-size 16px
    font-weight 500
    color: $blue

    .icon-info
      vertical-align top
      margin-top -2px

  &__text
    padding-left 3px

  &__hint
    position absolute

.bubble
  position absolute
  z-index: 1;
  padding 24px
  width 286px
  background-color #fff
  box-shadow 0 2px 4px 0 rgba(0, 0, 0, 0.15)
  visibility hidden
  opacity 0
  font-size 14px
  text-align center
  line-height 24px
  font-weight normal
  &:before
  &:after
    transform rotate(45deg)
    transform-origin right
    position absolute
    border-style solid
    right 100%
    top 100%
    content ''
    z-index 1
  &:before
    border-width 17px 6px 0
    border-color rgba(0, 0, 0, .05) transparent transparent
    margin -1px -4px 0
  &:after
    border-width 16px 5px 0
    border-color white transparent transparent
    margin -3px -3px 0


[data-bubble-parent]
  &:hover
    .bubble
      opacity 1
      visibility visible

.change-info-form
  margin 0 auto
  max-width 310px

.study-preview
  display flex
  flex-wrap wrap
  font-size 14px
  line-height 24px
  font-weight normal
  justify-content space-between
  align-items center
  &__text
    max-width calc(100% - 60px)
    color $black
  &__percentage
    font-size 16px
    font-weight 500
    width 60px
    text-align right
  &__progress
    position relative
    width 100%
    margin 10px 0 0
    background $gray
    height 4px
    border-radius 3px
  &__progress-inner
    position absolute
    left 0
    top 0
    height 100%
    background-image linear-gradient(271deg, #2ea694, #2ba08f 6%, #033943)
    border-radius 3px

.study-preview-list
  display flex
  flex-wrap wrap
  justify-content space-between
  margin-bottom -15px
  &__item
    width 422px
    margin 0 0 15px
  &after
    display block
    width 422px
    content ''
  &+.btn-wrap
    margin-top: 30px
    
.study-preview-wrap
  margin 70px 0
  
.study-preview-heading
  text-align center
  font-size 18px
  font-weight 500
  margin-bottom 20px
    
/*!===== end Profile Page styles ====*/
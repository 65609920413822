/*!===== Authorization page styles ====*/
.authorization
  display flex
  align-items center
  justify-content center
  margin-top -120px
  min-height 100vh
  width 100%
  padding 60px 15px
  font-size 14px
  background-color #fff

  &-container
    max-width 550px
  &-form
    margin 50px auto
    max-width 310px

    .remember-pass  
      align-self flex-end

    .form-row--submit
      justify-content space-between
      align-items center
    
  &-step
    // display none
    &.is-current
      display block
  &-steps
    display flex
    align-items center
    justify-content center
    &__item
      margin 0 10px
      width 24px
      height 24px
      border-radius 50%
      border 1px solid #d4d4d4
      color #d4d4d4
      text-align center
      &.is-current
        color $blue
        border-color $blue
/*!===== end Authorization Page styles ====*/